
@import "global_var";

.prof-card{
    flex: 1 0 48%;
    margin: 5px;
    height: 220px;
    min-width: 330px !important;
    position: relative;
    .card-body{
        .fav-btn{
            position: absolute;
            right: 10px;
            top: 5px;
            padding: 0;
            &:focus{
                outline: 0 !important;
                box-shadow: none !important;
            }
            img{
                width: 15px;
            }
        }
        .prof-details{
            display: flex;
            height: 100%;
            .profile-pic{
                padding-right: 15px;
                img{
                    border-radius: 50%;
                    height: 67px;
                    width: 67px;
                    object-fit: cover;
                    margin-bottom: 15px;
                }
                .prof-feature{
                    font-weight: normal;
                    font-size: 10px;
                    line-height: 15px;
                    color: #676767;
                    .feature{
                        margin-bottom: 5px;
                        display: flex;
                        align-items: center;
                        .consultation-icon{
                            border-radius: 0;
                            width: 18px;
                            margin-right: 5px;
                            margin-bottom: 0;
                            height: auto;
                        }
                    }
                }
            }
            .details{
                width: 100%;
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                .name-row{
                    .name{
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 21px;
                        color: #231D1D;
                        text-decoration: none;
                    }
                }
                .specialty{
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 18px;
                    color: #676767;
                    margin-bottom: 5px;
                }
                .clinic-row{
                    display: flex;
                    align-items: center;
                    margin-bottom: 5px;
                    img{
                        width: 23px;
                        height: 23px;
                        margin-right: 5px;
                    }
                    .clinic-name{
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 18px;
                        color: #676767;
                        text-decoration: none;
                    }
                }
                .feature-tags{
                    display: flex;
                    .feature-tag{
                        border-radius: 5px;
                        color: white;
                        font-weight: 600;
                        font-size: 10px;
                        line-height: 15px;
                        padding: 2px 5px;
                        display: inline-flex;
                        margin: 3px 0;
                        margin-right: 5px;
                        &.online-booking{
                            background: #346FF1;
                        }
                        &.corporate-panel{
                            background: #FD9B5B;
                        }
                    }
                }
                .contact-container{
                    display: flex;
                    margin-top: 10px;
                    justify-content: space-between;
                    margin-bottom: 0;
                    margin-top: auto;
                    .book-online-btn{
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 22px;
                        text-align: center;
                        color: #FFFFFF;
                        background: #F25C54;
                        border-radius: 30px;
                        padding: 12px 15px;
                        text-decoration: none;
                    }
                    .phone-email-container{
                        display: flex;
                        align-items: center;
                        a{
                            &:first-child{
                                margin-right: 5px;
                            }
                        }
                        .contact-icon{
                            max-height: 35px;
                            max-width: 35px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $breakpoint-tablet) {
    .prof-card {
        .card-body{
            .prof-details {
                .details{
                    .contact-container{
                        .book-online-btn{
                            font-size: 13px;
                            padding: 5px 15px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $breakpoint-large-phone) {
    .prof-card {
        flex: 100%;
    }
}