
.error-div{
    padding: 5rem;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #231D1D;
}

