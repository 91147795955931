@import "global_var";

.clinic-page{
    .header{
        margin-top: 50px;
        margin-bottom: 50px;
        color: black;
        text-align: center;
        .title{
            font-weight: 600;
            font-size: 36px;
            line-height: 54px;
            color: #231D1D;
            margin-bottom: 1rem;
        }
        .bread-crumb{
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #231D1D;
            margin-bottom: 1rem;
        }
    }
    .header-line{
        border: 1px solid #231D1D;
    }

    .mobile-v{
        display: none;
    }

    .body{
        display: flex;
        padding-top: 2rem;
        padding-bottom: 2rem;
        .clinic-details{
            max-width: 350px;
            width: 100%;
            .img-slider{
                width: 100%;
                margin-bottom: 1rem;
                .carousel{
                    .carousel-inner{
                        border-radius: 25px;
                        .carousel-item{
                            img{
                                height: 300px;
                                background-color: black;
                                object-fit: contain;
                            }
                        }
                    }
                    .carousel-indicators{
                        li{
                            width: 10px;
                            height: 10px;
                            border-radius: 100%;
                        }
                    }
                }
            }

            .operation-hours-container{
                background: #F9F9F9;
                border-radius: 5px;
                padding: 15px;
                width: 100%;
                .title{
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 18px;
                    color: #676767;
                    margin-bottom: 10px;
                }
                .operation-hours{
                    .day-row{
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 21px;
                        color: #676767;
                        white-space: pre-line;
                        margin-bottom: 5px;
                        display: flex;
                        .day{
                            width: 110px;
                        }
                        .punctuation{
                            padding-right: 15px;
                        }
                    }
                }
            }
        }

        .clinic-sub-details{
            margin-left: 60px;
            width: 100%;
            .details-container{
                flex: 0 70%;
                display:flex;
                margin-bottom: 25px;

                .address-col{
                    width: 100%;
                    .address-name{
                        font-weight: 600;
                        font-size: 22px;
                        line-height: 33px;
                        color: #231D1D;
                        margin-bottom: 5px;
                    }
                    .address{
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        color: #231D1D;
                        margin-bottom: 20px;
                    }
                    .direction-tag{
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 21px;
                        color: #F25C54;
                        text-decoration: none;
                        padding: 0px;

                        &:focus{
                            outline: 0 !important;
                            box-shadow: none !important;
                        }
                    }
                    .phone-email-container{
                        margin-top: 15px;
                        .contact-icon{
                            max-height: 50px;
                            max-width: 50px;
                            margin-right: 15px;
                        }
                    }
                }

                .map-container{
                    height: 275px;
                    margin-bottom: 35px;
                }
            }
            .details-line{
                margin: 50px 0;
                border-color:#4E4E4E;
            }
            .tabs-container{
                &.nav-tabs{
                    border: 0px;
                    margin-bottom: 30px;
                    .nav-link{
                        width: 33.33%;
                        text-align: center;
                        border: 0px;
                        font-weight: normal;
                        font-size: 18px;
                        line-height: 27px;
                        color: #676767;
                        &.active{
                            border-bottom: 3px solid #F25C54;
                            font-weight: 500;
                            color: #F25C54;
                        }
                    }
                }
            }

            .tab-detail-container{
                .tab-title{
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 27px;
                    color: #676767;
                    margin-bottom: 15px;
                }
                .tab-content{
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 21px;
                    color: #676767;
                    white-space: pre-line;

                    &.list{
                        padding-inline-start: 20px;
                        li{
                            margin-bottom: 5px;
                        }
                    }
                }
                &.prof{
                    display: flex;
                    flex-wrap: wrap;
                }

            }
        }
    }
}

@media (max-width: $breakpoint-992) {
    .clinic-page {
        .desktop-v{
            display: none;
        }
        .mobile-v{
            display: flex;
        }
        .body{
            flex-direction: column;
            .clinic-details{
                max-width: 100%;
                .img-slider{
                    position: relative;
                    .carousel{
                        .carousel-inner{
                            .carousel-item{
                                img{
                                    height: 400px;
                                }
                            }
                        }
                    }
                }
            }
            .clinic-sub-details{
                margin-left: 0;
                .details-container{
                    .address-col{
                        .address-name{
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 27px;
                            margin-top: 1rem;
                            margin-bottom: 0.5rem;
                        }
                    }
                }
            }
        }
    }
}