@import "global_var";

.appointments-div{
    .MuiPagination-root{
        .MuiPagination-ul{
            margin: 1.5rem auto 3rem auto;
        }
    }
}
.appointments-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    &.add-after-space{
        .appointment-card:last-child{
            margin-left: 0.9rem;
        }
        &:after {
            content: "";
            flex: auto;
        }
    }

    .appointment-card{
        flex: 0 0 48%;
        margin: 10px 0;
        background: #FFFFFF;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        border: none;
        .card-header{
            display: flex;
            justify-content: space-between;
            background: #F9F9F9;
            border-radius: 5px 5px 0px 0px;
            border: none;
            .datetime-div{
                display: flex;
                align-items: center;
                .datetime{
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    color: #231D1D;
                    margin-top: 2px;
                }
                img{
                    width: 23px;
                    height: 23px;
                    margin-right: 7px;
                }
            }
            .more-btn{
                padding: 0;
                &:focus{
                    box-shadow: none;
                }
            }
            .dots-icon{
                width: 25px;
            }
        }
        .card-body{
            .profile-pic{
                padding-right: 15px;
                img{
                    border-radius: 50%;
                    height: 67px;
                    width: 67px;
                    object-fit: cover;
                }
            }
            .appointment-info{
                display: flex;

                .details-container{
                    width: 100%;
                    .details{
                        width: 100%;
                        display: flex;

                        .details-div{
                            width: 100%;
                        }
                        
                        .name-row{
                            display: flex;
                            justify-content: space-between;
                            .name{
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 24px;
                                color: #231D1D;
                                text-decoration: none;
                            }
                            .appointment-status{
                                font-weight: normal;
                                font-size: 16px;
                                line-height: 24px;
                                text-align: right;
                                &.cancelled{
                                    color: #F25C54;
                                }
                                &.attended{
                                    color: #51B848;
                                }
                            }
                        }

                        .specialty{
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 21px;
                            color: #676767;
                            margin-bottom: 7px
                        }
                        .clinic-row{
                            display: flex;
                            img{
                                width: 18px;
                                height: 100%;
                                margin-right: 10px;
                            }
                            .clinic-name{
                                text-decoration: none;
                                font-weight: normal;
                                font-size: 14px;
                                line-height: 21px;
                                color: #676767;
                            }
                        }
                    }

                    .patient-details{
                        margin-top:10px;
                        .row-info{
                            display: flex;
                            justify-content: space-between;
                            margin-bottom: 5px;
                            .col-label{
                                font-style: normal;
                                font-weight: normal;
                                font-size: 14px;
                                line-height: 21px;
                                color: #676767;
                            }
                            .col-value{
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 21px;
                                text-align: right;
                                color: #676767;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $breakpoint-992) {
    .appointments-list{
        .appointment-card{
            flex: 100%;
            .mobile-v{
                display: block !important;
            }
            .desktop-v{
                display: none;
            }
        }
    }
}