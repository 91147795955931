@import "global_var";

$main_img: '../images/main_img.png';

$teleconsult-background-color: #F9F9F9;
$hp_bg: '../images/healthcare_prof_img.png';
$experts_img: '../images/experts_img.png';
$custom-calendar-icon:  '../images/calendar-icon.svg';
$download-bg: '../images/download_bg_img.png';

$experts_img_transition_in: transform 0.1s, filter 3s ease-in-out;
$experts_img_transition_out: transform 5s, filter 3s ease-in-out;

.disabled{
    pointer-events: none;
    //background-color: #e9ecef !important;
    opacity: 1;

    div[class*="-control"]{
        background-color: #e9ecef;
        opacity: 1;
    }
}

.home-page{
    .section{
        .center-content {
            margin-top: 85px;
            margin-bottom: 85px
        }

        .section-heading{
            font-weight: 500;
            font-size: 28px;
            line-height: 42px;
            letter-spacing: 0.02em;
            color: #4E4E4E;
        }

        .section-desc{
            font-size: 18px;
            line-height: 27px;
            color: #4E4E4E;
        }

        .divider{
            width: 120px;
            border-color: #4E4E4E;
            margin: 30px auto;
        }
    }

    .section-btn{
        background-color: #EC2727;
        border-radius: 30px;
        font-weight: 600;
        font-size: 14px;
        text-align: center;
        color: #FFFFFF;
        padding: 12px 18px 12px 18px;

        &:hover{
            background-color: #d32323;
            color: #FFFFFF;
        }
    }

    .main-section{
        background-image: url($main_img);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        padding: 185px 0 120px 0;
        margin-top: -100px;

        .container{
            .left-content{
                max-width: 650px;
                flex-direction: column;
                justify-content: flex-start;
            }

            .section-form-container{
                width: 75%;

                .section-form{
                    .section-field{
                        border-radius: 30px;
                        border: 1px solid #D1D1D1;
                        font-size: 14px;
                        line-height: 21px;
                        color: #9F9F9F;
                    }

                    .speciality-field{
                        margin-bottom: 15px;

                        input{
                            opacity: 1 !important;
                        }

                        div[class*="-control"]{
                            border-radius: 30px;
                            border: 1px solid #D1D1D1;
                            font-size: 14px;
                            line-height: 21px;
                            color: #9F9F9F;
                        }
                        span[class*="-indicatorSeparator"]{
                            background-color: transparent;
                        }
                        div[class*="-placeholder"]{
                            color: #9F9F9F;
                        }

                        div[class*="-singleValue"]{
                            width: 98%;
                        }

                        .specialty-label{
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            
                            .suggestion-type{
                                color: #9F9F9F;
                                text-transform: capitalize;
                            }
                        }
                    }

                    .section-field-loc-date{
                        display: flex;
                        flex-direction: row;
                        
                        .date-group{
                            margin-bottom: 0;
                            position: relative;

                            .custom-calendar-icon{
                                width: 35px;
                                height: 35px;
                                background-image: url($custom-calendar-icon);
                                background-repeat: no-repeat;
                                position: absolute;
                                top: 2px;
                                right: 13px;
                            }

                            .date-field{
                                flex: 1 40%;
                            }

                            .react-datepicker-wrapper{
                                margin-right: 10px;
                            }
                            // date
                            .react-datepicker__header {
                                font-family: "Poppins", Helvetica, Arial, Verdana, sans-serif !important;
                                background-color: white;
                                border-bottom: none;
                                .react-datepicker__current-month{
                                    color: #4E4E4E;
                                }
                                .react-datepicker__day-names{
                                    font-weight: bold;
                                    .react-datepicker__day-name{
                                        color: #4E4E4E;
                                    }
                                }
                            }
                            .react-datepicker__day{
                                color: #4E4E4E;
                            }
                            .react-datepicker__day--selected{
                                color: white;
                                background-color: #EC2727;
                                font-weight: bold;
                            }
                            .react-datepicker__day--keyboard-selected{
                                background-color: transparent;
                            }
                            .react-datepicker__day--disabled{
                                color: #BDBDBD;
                            }
                        }

                        .location-field{
                            flex: 1 40%;
                            margin-right: 10px;

                            div[class*="-control"]{
                                border-radius: 30px;
                                border: 1px solid #D1D1D1;
                                font-size: 14px;
                                line-height: 21px;
                                color: #9F9F9F;
                            }
                            span[class*="-indicatorSeparator"]{
                                background-color: transparent;
                            }

                            div[class*="-placeholder"]{
                                color: #9F9F9F;
                            }
                        }

                        .search-btn{
                            flex: 1 20%;
                            padding: 0 25px;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                }
            }
        }

        .section-heading{
            font-weight: bold;
            font-size: 31px;
            line-height: 46px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: #4E4E4E;
            padding-bottom: 15px;
        }

        .section-desc{
            font-size: 18px;
            line-height: 27px;
            color: #4E4E4E;
            padding-bottom: 40px;
        }

    }
    
    .steps-section{
        display: flex;
        flex-direction: row;

        .steps-box{
            flex: 1 1 25%;
            color: #FFFFFF;
            padding: 20px;

            display: flex;
            justify-content: center;
            // align-items: center;

            .steps-container{
                display: flex;
                flex-direction: row;

                .first-col{
                    min-width: 50px;
                    margin-right: 10px;
                    .num{
                        background: #FFFFFF;
                        color: red;
                        border-radius: 50px;
        
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-weight: bold;
                        font-size: 33px;
                    }
                }
                .second-col{
                    .title{
                        word-wrap: break-word;
                        padding-bottom: 10px;
                        font-weight: 600;
                        font-size: 23px;
                        line-height: 125%;
                        letter-spacing: 0.02em;
                        text-transform: capitalize;
                    }

                    .steps-desc{
                        font-size: 14px;
                        line-height: 21px;
                    }
                   
                }
            }

            &.first-box{
                background-color: #EC2727;
                font-weight: bold;
                font-size: 33px;
                line-height: 49px;
                letter-spacing: 0.02em;
                text-transform: capitalize;
                align-items: center;
            }

            &.second-box{
                display: flex;
                background-color: #4E4E4E;
            }
        }
    }

    .common-search-section{
        .common-items{
            justify-content: center;
            .common-item-container{
                text-decoration: none;
                border-radius: 15px;
                margin: 15px;
                width: 370px;
                box-shadow: 0px 4px 15px 5px #F1F1F1;
                border: none;
                color: #4E4E4E;
                padding: 50px;

                &:hover{
                    background-color: #9F9F9F;
                    color: white;
                }

                .card-body{
                    padding: 0;
                    .card-icon{
                        margin-bottom: 25px;

                        img{
                            width: 75px;
                            height: 75px;
                        }
                    }
    
                    .card-title{
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 33px;
                        text-align: center;
                        letter-spacing: 0.02em;
                    }
    
                    .card-text{
                        font-weight: normal;
                        font-size: 15px;
                        line-height: 23px;
                        text-align: center;
                    }
                }
            }
        }

        .center-content {
            margin-top: 85px;
            margin-bottom: 85px
        }

        .common-search-btn-container{
            justify-content: center;
            padding-top: 70px;
        }
    }

    .teleconsult-section{
        background-color: $teleconsult-background-color;

        .teleconsult-body{
            justify-content: center;

            .teleconsult-img-container{
                max-width: 530px;
                // width: 50%;
                margin-right: 1.5rem;

                img{
                    width:100%;
                }
            }
            .teleconsult-details{
                margin-left: 1.5rem;

                .detail-title{
                    font-weight: 500;
                    font-size: 22px;
                    line-height: 33px;
                    letter-spacing: 0.02em;
                    color: #4E4E4E;
                }

                .detail-list{
                    .list-item{
                        padding: 10px 0;

                        .fa-check{
                            -webkit-text-stroke: 3.5px $teleconsult-background-color;
                            padding-right: 1rem;
                            font-size: 25px;
                            color: #F25C54;
                        }

                        .item-text{
                            font-size: 18px;
                            line-height: 27px;
                            color: #4E4E4E;
                        }
                    }
                }

                .teleconsult-btn-container{
                    justify-content: center;
                    padding-top: 1.5rem;
                }
            }
        }
    }

    .healthcare-prof-section{
        background-image: url($hp_bg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        .left-content{
            margin: 60px 150px;

            .section-heading{
                font-weight: 500;
                font-size: 28px;
                line-height: 42px;
                letter-spacing: 0.02em;
                color: #4E4E4E;
            }
        }
        
        .divider{
            width: 120px;
            border-color: #4E4E4E;
            margin: 30px 0 30px 0;
        }

        .booking-number{
            margin-bottom: 16px;
            font-weight: 600;
            font-size: 40px;
            line-height: 60px;
            letter-spacing: 0.02em;
            text-transform: capitalize;
            color: #EC2727;
        }

        .section-desc{
            font-size: 18px;
            line-height: 27px;
            color: #4E4E4E;
            flex-direction: column;
            width: 555px;
            p{
                margin-bottom: 1.5rem;
            }
            // width: 49%;
        }
    }

    .experts-section{
        background-image: url($experts_img);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: left;
        // background-color: gray;

        .section-heading{
            font-weight: 500;
            font-size: 28px;
            line-height: 42px;
            text-align: center;
            letter-spacing: 0.02em;
            color: #4E4E4E;
        }

        .top-part{
            display: flex;
            justify-content: flex-end;
        }

        .divider{
            width: 120px;
            border-color: #4E4E4E;
            margin: 30px auto;
        }

        .right-content{
            max-width: 740px;
            width: 100%;
            margin: 100px 50px;

            .section-desc{
                justify-content: center;
                .carousel{
                    width: 100%;
                    .carousel-indicators {
                        bottom: -55px;
                        li{
                            width: 10px;
                            height: 10px;
                            border-radius: 100%;
                            background-color: #E6E6E6;
                            &.active{
                                background-color: #4E4E4E;
                            }
                        }
                    }
                    .carousel-item{
                        width: 100%;
                        height: 200px;
                        .carousel-caption{
                            color: #4E4E4E;
                            bottom: auto;

                            .expert-desc{
                                font-size: 18px;
                                line-height: 27px;
                                padding: 0 10px 39px 10px;
                                position: relative;

                                .fa-quote-right{
                                    position: absolute;
                                    color: #EC2727;
                                    font-size: 23px;
                                    left: -12px;
                                    top: -18px;
                                    -webkit-transform: scaleX(-1);
                                    transform: scaleX(-1);
                                }
                            }

                            .expert-name{
                                font-weight: 600;
                                font-size: 17px;
                                line-height: 25px;
                                text-align: center;
                                letter-spacing: 0.02em;
                                padding-bottom: 5px;
                            }

                            .expert-from{
                                font-size: 14px;
                                line-height: 21px;
                                text-align: center;
                                letter-spacing: 0.02em;
                            }
                        }
                    }
                }
            }
        }
    }

    .featured-section{
        background-color: #F9F9F9;
        .center-content {
            margin-top: 85px;
            margin-bottom: 85px
        }
        .featured-items{
            justify-content: center;
            .featured-img-container{
                padding: 15px 15px;

                img{
                    width: 170px;
                    height: auto;
                }
            }
        }
    }

    .expert-column-section{
        .expert-column-lists{
            margin: 0 150px;

            .main-expert-column, .expert-column-items{
                flex: 0 1 50%;
            }

            .main-expert-column{
                padding-right: 30px;

                .main-expert-column-img-container{
                    position: relative;
                    max-width: 520px;
                    max-height: 390px;
                    width: 100%;
                    height: 100%;
                    margin-bottom: 18px;
                    overflow: hidden;

                    img{
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                        transform-origin: 50% 50%;
                        transition: $experts_img_transition_in;
                        filter: brightness(150%);
                    }

                    .column-date-container{
                        position: absolute;
                        top: 15px;
                        left: 15px;
                        text-align: center;
                        box-shadow: 0px 4px 15px 5px #f1f1f1;
                        padding: 7px 14px;
                        background-color: white;
                        z-index: 1;

                        .column-date{
                            font-weight: bold;
                            font-size: 25px;
                            line-height: 37px;
                            letter-spacing: 0.02em;
                            text-transform: capitalize;
                            color: #EC2727;

                        }
                        .column-month-year{
                            font-weight: 500;
                            font-size: 10px;
                            line-height: 15px;
                            text-align: center;
                            letter-spacing: 0.02em;
                            text-transform: capitalize;
                            color: #4E4E4E;
                        }
                    }

                    &:hover{
                        img{
                            filter: brightness(100%);
                            transform: scale(1.2);
                            transition: $experts_img_transition_out;
                        }

                        &:after{
                            background: rgba(237,237,237,0.5);
                            box-shadow: none;
                        }
                    }

                    &:after{
                        content: '';
                        display: block;
                        height: 100%;
                        width: 100%;
                        position: absolute;
                        left: 0;
                        top: 0;
                        background-color: rgba(255, 0, 0, 0);
                        transition: background-color 1s ease;
                    }
                }

                .expert-tag{
                    color: #01adb9;
                    font-size: 12px;
                    line-height: 14px;
                    letter-spacing: 0.5px;
                    margin-bottom: 6px;
                    text-transform: uppercase;
                    font-weight: 700;
                }
    
                .expert-title{
                    font-weight: 700;
                    margin: 0;
                    font-size: 30px;
                    line-height: 34px;
                    margin-bottom: 10px;
                    a{
                        text-decoration: none;
                        color: #4e4e4e;
                    }
                    &:hover{
                        a{
                            color: #ec2727;
                        }
                    }
                }
    
                .expert-desc{
                    font-size: 16px;
                    line-height: 22px;
                    margin-bottom: 5px;
                }
    
                .expert-author{
                    color: #BDBDBD;
                    width: 127px;
                    height: 21px;
                    font-size: 14px;
                    line-height: 21px;
                }
            }

            .expert-column-items{
                padding-left: 30px;

                .expert-column{
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 1.5rem;

                    .expert-column-img-container{
                        position: relative;
                        width: 185px;
                        height: 138px;
                        flex: 1 30%;
                        margin-right: 20px;
                        overflow: hidden;
    
                        img{
                            object-fit: cover;
                            width: 100%;
                            height: 100%;

                            transform-origin: 50% 50%;
                            transition: $experts_img_transition_in;
                            filter: brightness(150%);
                        }
    
                        .column-date-container{
                            position: absolute;
                            top: 8px;
                            left: 8px;
                            text-align: center;
                            box-shadow: 0px 4px 15px 5px #f1f1f1;
                            padding: 5px 7px;
                            background-color: white;
                            z-index: 1;
    
                            .column-date{
                                font-weight: bold;
                                font-size: 18px;
                                line-height: 27px;
                                letter-spacing: 0.02em;
                                text-transform: capitalize;
                                color: #EC2727;
    
                            }
                            .column-month-year{
                                font-weight: 500;
                                font-size: 7px;
                                line-height: 10px;
                                text-align: center;
                                letter-spacing: 0.02em;
                                text-transform: capitalize;
                                color: #4E4E4E;
                            }
                        }

                        &:hover{
                            img{
                                filter: brightness(100%);
                                transform: scale(1.2);
                                transition: $experts_img_transition_out;
                            }
    
                            &:after{
                                background: rgba(237,237,237,0.5);
                                box-shadow: none;
                            }
                        }

                        &:after{
                            content: '';
                            display: block;
                            height: 100%;
                            width: 100%;
                            position: absolute;
                            left: 0;
                            top: 0;
                            background-color: rgba(255, 0, 0, 0);
                            transition: background-color 1s ease;
                        }
                    }
                }

                .expert-information{
                    flex: 1 70%;
                }

                .expert-tag{
                    color: #01adb9;
                    font-size: 12px;
                    line-height: 14px;
                    letter-spacing: 0.5px;
                    margin-bottom: 6px;
                    text-transform: uppercase;
                    font-weight: 700;
                }
    
                .expert-title{
                    font-weight: 700;
                    margin: 0;
                    font-size: 26px;
                    line-height: 30px;
                    margin-bottom: 10px;
                    a{
                        text-decoration: none;
                        color: #4e4e4e;
                    }
                    &:hover{
                        a{
                            color: #ec2727;
                        }
                    }
                }
    
                .expert-desc{
                    font-size: 16px;
                    line-height: 22px;
                    margin-bottom: 5px;
                    display: -webkit-box;
                    overflow: hidden;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
    
                .expert-author{
                    color: #BDBDBD;
                    width: 127px;
                    height: 21px;
                    font-size: 14px;
                    line-height: 21px;
                }
            }
        }
        .more-articles{
            margin-top: 2rem;
            text-align: center;
            a{
                font-weight: 500;
                font-size: 18px;
                line-height: 27px;
                text-align: center;  
                color: #EC2727;
                text-decoration: none;
                cursor: pointer;

                .dot{
                    visibility: hidden;
                    opacity: 0;
                    transition: visibility 200ms linear, opacity 200ms linear, text-shadow 200ms ease;
                }
                &:hover{
                    color: rgb(211,35,35);
                    .dot{
                        visibility: visible;
                        opacity: 1;
                        text-shadow: rgb(211,35,35) 10px 0px, rgb(211,35,35) -10px 0px;
                        color: rgb(211, 35, 35);
                    }
                }
            }
        }
    }

    .download-section{
        background-image: url($download-bg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        display: flex;
        justify-content: center;

        .container{
            display: flex;
            justify-content: flex-end;
            margin: 100px 0;

            .right-content{
                max-width: 500px;
                width: 100%;

                .section-heading{
                    font-weight: 800;
                    font-size: 31px;
                    line-height: 46px;
                    letter-spacing: 0.03em;
                    text-transform: capitalize;
                    color: #FFFFFF;
                }
                .section-desc{
                    font-size: 18px;
                    line-height: 27px;
                    color: #FFFFFF;
                    padding-bottom: 45px;

                    .app-store{
                       color: #FFE066; 
                       text-decoration: none;
                    }
                }
                .download-btn-container{
                    .download-btn{
                        font-weight: 600;
                        font-size: 22px;
                        line-height: 33px;
                        text-align: center;
                        color: #FFFFFF;
                        padding: 12px 35px;
                    }
                }
            }
        }
    }
}

@media (max-width: $breakpoint-laptop) {
    .home-page{
        .expert-column-section{
            .expert-column-lists{
                margin: 0 50px;
            }
        }

        .experts-section{
            background-size: cover;
            background-position: center;
        }
    }

    .teleconsult-section{
        .teleconsult-body{
            .teleconsult-img-container{
                width: 40%;
            }
            .teleconsult-details{
                .detail-list{
                    .list-item{
                        padding: 5px 0 !important;
                    }
                }
            }
        }
    }
}

@media (max-width: $breakpoint-ipad-pro) {
    .home-page{
        .main-section{
            background-position: left center !important;
        }

        .experts-section{
            .right-content{
                width: 60% !important;
                margin: 100px 0px !important;
            }
        }

        .steps-section{
            flex-wrap: wrap;
            .steps-box{
                &.second-box{
                    .number{
                        width: 85px;
                    }
                }
                &.third-box{
                    .number{
                        width: 80px;
                    }
                }
                &.fourth-box{
                    .number{
                        width: 85px;
                    }
                }
                &.fifth-box{
                    .number{
                        width: 75px;
                    }
                }
            }
        }
    }
}

@media (max-width: $breakpoint-992) {

    .home-page{
        .section{
            .center-content{
                margin-left: 50px;
                margin-right: 50px;
            }
        }

        .main-section{
            padding: 120px 0 120px 0;
            margin-top: 0px;
        }

        .healthcare-prof-section{
            .left-content{
                margin: 60px auto;
            }
        }

        .expert-column-section {
            .expert-column-lists{
                .expert-column-items{
                    padding-left: 0;
                    flex: 0 1 100%;

                    .expert-title{
                        font-size: 23px;
                        line-height: 20px;
                    }
                }
                .main-expert-column{
                    padding-right: 0;
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 1.5rem;
                    flex: 0 1 100%;

                    .main-expert-column-img-container{
                        width: 185px;
                        height: 138px;
                        margin-bottom: 0;
                        margin-right: 20px;
                        flex: 1 1 30%;
                        .column-date-container{
                            padding: 5px 7px;
                            top: 8px;
                            left: 8px;
                            .column-date{
                                font-size: 18px;
                                line-height: 27px;
                            }
                            .column-month-year{
                                font-size: 7px;
                                line-height: 10px;
                            }
                        }
                    }
                    .expert-information{
                        flex: 1 1 70%;
                    }
                    .expert-title{
                        font-size: 26px;
                        line-height: 30px;
                        margin-bottom: 10px;
                        font-size: 23px;
                        line-height: 20px;
                    }
                }
            }
        }

        .common-search-section{
            .common-items{
                .common-item-container{
                    width: 300px;
                }
            }
        }
    }

    .teleconsult-section{
        .teleconsult-body{
            .teleconsult-img-container{
                width: 55%;
                margin-right: 0;
                margin-bottom: 1.5rem;
            }
        }
    }

    .steps-section{
        .steps-box{
            .number{
                height: 45px !important;
            }
        }
    }
}

@media (max-width: $breakpoint-tablet) {
    .home-page{
        .experts-section{
            .right-content{
                width: 100% !important;
            }
        }
    }

    .home-page {
        .expert-column-section {
            .expert-column-lists{
                .expert-column-items{
                    padding-left: 0;
                    flex: 0 1 100%;
                    .expert-title{
                        font-size: 23px;
                        line-height: 20px;
                    }
                }
                .main-expert-column{
                    padding-right: 0;
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 1.5rem;
                    flex: 0 1 100%;

                    .main-expert-column-img-container{
                        width: 185px;
                        height: 138px;
                        margin-bottom: 0;
                        margin-right: 20px;
                        flex: 1 1 30%;
                        .column-date-container{
                            padding: 5px 7px;
                            top: 8px;
                            left: 8px;
                            .column-date{
                                font-size: 18px;
                                line-height: 27px;
                            }
                            .column-month-year{
                                font-size: 7px;
                                line-height: 10px;
                            }
                        }
                    }
                    .expert-information{
                        flex: 1 1 70%;
                    }
                    .expert-title{
                        font-size: 26px;
                        line-height: 30px;
                        margin-bottom: 10px;
                        font-size: 23px;
                        line-height: 20px;
                    }
                }
            }
        }

        .main-section{
            .container{
                .section-form-container{
                    width: 100%;
                    .section-form{
                        .section-field-loc-date{
                            flex-direction: column;

                            .location-field{
                                margin-right: 0;
                                margin-bottom: 15px;
                            }
                            .date-group{
                                .react-datepicker-wrapper{
                                    width: 100%;
                                    margin-bottom: 15px;
                                }
                            }

                            .search-btn{
                                padding: 7px 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $breakpoint-large-phone) {
    .home-page{
        .section {
            .center-content{
                margin-left: 15px;
                margin-right: 15px;
            }
        }

        .steps-section{
            .steps-box{
                flex: 1 0 100%;
                &.second-box{
                    justify-content: flex-start;
                }
            }
        }

        .experts-section{
            background-position: right;

            .right-content {
                .section-desc{
                    .carousel{
                        .carousel-item{
                            .carousel-caption {
                                .expert-name{
                                    font-size: 14px;
                                    line-height: 16px;
                                }
                                .expert-desc{
                                    font-size: 16px;
                                    line-height: 22px;
                                    padding: 0 10px 5px 10px;
                                }
                                .expert-from{
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .main-section {
            padding: 100px 0 70px 0;
            .section-heading{
                font-size: 27px;
                line-height: 36px;
                text-align: center;
            }

            .section-desc{
                text-align: center;
            }
            .container{
                .section-form-container{
                    .section-form{
                        .speciality-field{
                            margin-bottom: 10px;
                        }
                        .section-field-loc-date{
                            flex-direction: column;

                            .location-field{
                                flex: 1 100%;
                                margin-right: 0;
                                margin-bottom:10px;
                            }

                            .react-datepicker-wrapper{
                                margin-bottom: 10px;
                                margin-right: 0;
                            }

                            .search-btn{
                                flex: 1 1 100%;
                                padding: 5px 25px;
                            }
                        }
                    }
                }
            }
        }

        .healthcare-prof-section{
            .left-content{
                margin: 60px 15px;
                width: 100%;

                .section-heading{
                    text-align: center;
                }
            }
            .divider{
                margin: 30px auto;
            }
            .booking-number{
                text-align: center;
            }
            .section-desc{
                width: 100%;
            }
            .practice-btn-container{
                justify-content: center;
            }
        }

        .expert-column-section{
            .center-content{
                padding-left: 15px;
                padding-right: 15px;
            }
            .expert-column-lists{
                margin: 0;
            }
        }
    }
}
