.privacy-page{
    .header{
        margin-top: 50px;
        margin-bottom: 50px;
        color: black;
        text-align: center;
        .title{
            font-weight: 600;
            font-size: 36px;
            line-height: 54px;
            color: #231D1D;
            margin-bottom: 1rem;
        }
        .bread-crumb{
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #231D1D;
            margin-bottom: 1rem;
        }
    }
    .header-line{
        border: 1px solid #231D1D;
        margin-bottom: 50px;
    }

    .body{
        .privacy__title{
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 33px;
            color: #676767;
            text-decoration: underline;
            text-align: center;
        }
        p{
            color: #676767;
        }
        .privacy__list--main{
            padding: 1rem;
            li{
                margin-bottom: 2.5rem;
            }
            li::marker{
                font-size: 1rem;
                line-height: 27px;
                font-weight: 500;
                color: #676767;
            }
            .privacy__list--sub{
                li{
                    margin-bottom: 1rem;
                }
            }
        }
        h3,.privacy__desc{
            font-size: 1rem;
            line-height: 27px;
            color: #676767;
            margin-bottom: 0;
        }
        .privacy__desc{
            font-style: italic;
        }
        h4{
            margin-bottom: 0;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #676767;
        }
        .privacy__pdpa-wrapper{
            flex-direction: column;
            align-items: center;
            margin-top: 1rem;
            margin-bottom: 1rem;
            .privacy__item{
                display: flex;
                a{
                    color: #f05450;
                }
                h4{
                    margin-right: 10px;
                }
            }
        }
    }
}