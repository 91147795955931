@import "global_var";

$main_img: '../images/search_result_bg.png';
$main_filter_layer: '../images/search-result-layer.png';

$teleconsult-background-color: #F9F9F9;
$hp_bg: '../images/healthcare_prof_img.png';

.gm-style-iw-d{
    overflow: auto !important;
}
.gm-style .gm-style-iw-c{
    padding: 0 !important;
}
.gm-style .gm-style-iw-t{
    &:after{
        background: none !important;
        box-shadow: none !important;
    }
}

.result-page{
    .section-btn{
        background-color: #EC2727;
        border-radius: 30px;
        font-weight: 600;
        font-size: 14px;
        text-align: center;
        color: #FFFFFF;

        &:hover{
            background-color: #d32323;
            color: #FFFFFF;
        }
    }

    .MuiPagination-root{
        .MuiPagination-ul{
            margin: 1.5rem auto 3rem auto;
        }
    }

    .main-section{
        background-image: url($main_filter_layer), url($main_img);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        .container{
            padding-top: 200px;
            padding-bottom: 100px;
            margin-top: -100px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .main-title{
                font-weight: bold;
                font-size: 31px;
                line-height: 46px;
                text-align: center;
                letter-spacing: 0.05em;
                text-transform: uppercase;
                color: #4E4E4E;
                padding-bottom: 10px;
            }
            .main-sub-title{
                font-size: 18px;
                line-height: 27px;
                text-align: center;
                color: #4E4E4E;  
                padding-bottom: 50px;
            }

            .search-bar{
                display: flex;
                justify-content: center;
                .section-form{
                    display: flex;
                    flex-direction: row;
                    width: 80%;
                    
                    .speciality-group{
                        flex: 1 1 60%;
                        margin-right: 10px;

                        .speciality-field{
                            input{
                                opacity: 1 !important;
                            }
                        }
    
                        div[class*="-control"]{
                            border-radius: 30px;
                            border: 1px solid #D1D1D1;
                            font-size: 14px;
                            line-height: 21px;
                            color: #9F9F9F;
                        }
                        span[class*="-indicatorSeparator"]{
                            background-color: transparent;
                        }
                        div[class*="-placeholder"]{
                            color: #9F9F9F;
                        }
                        div[class*="-singleValue"]{
                            width: 98%;
                        }

                        .specialty-label{
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            
                            .suggestion-type{
                                color: #9F9F9F;
                                text-transform: capitalize;
                            }
                        }
                    }
    
                    .location-group{
                        flex: 1 1 25%;
                        margin-right: 10px;
    
                        div[class*="-control"]{
                            border-radius: 30px;
                            border: 1px solid #D1D1D1;
                            font-size: 14px;
                            line-height: 21px;
                            color: #9F9F9F;
                        }
                        span[class*="-indicatorSeparator"]{
                            background-color: transparent;
                        }
    
                        div[class*="-placeholder"]{
                            color: #9F9F9F;
                        }
                    }
    
                    .button-group{
                        flex: 1 1 15%;
                    }
                }
            }

        }
    }

    .result-list-container{

        .filter-bar{
            display: flex;
            justify-content: space-between;
            align-items: center;

            .left-filters{
                display: flex;
                .filter-field{
                    margin-left: 1rem;
                    margin-right: 1rem;
                    width: 150px;

                    &.language-field{
                        width: 200px;
                    }
                    &.MuiFormControl-root{
                        margin-top: 16px;
                        margin-bottom: 8px;
                    }
                }
            }

            .right-items{
                .map-icon{
                    width: 15px;
                }

                .map-view-btn{
                    text-decoration: none;
                    font-size: 16px;
                    line-height: 24px;
                    color: #F25C54;
                    &:focus{
                        outline: 0 !important;
                        box-shadow: none !important;
                    }
                }
            }

            .mobile-filters{
                display: none;
            }
        }

        .list-header{
            display: flex;
            justify-content: flex-end;
            background: #F9F9F9;
            padding: 1rem;
            margin-bottom: 1rem;
            justify-content: space-between;
            align-items: center;
            
            .result-number{
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
                color: #4E4E4E;
            }

            .date-bar{
                display: flex;
                flex-direction: row;

                .dates{
                    margin: 0 10px;
                    display: flex;

                    .date-item{
                        text-align: center;
                        padding: 0 1.5rem;

                        &:first-of-type {
                            padding-left: 0;
                        }

                        &:last-of-type {
                            padding-right: 0;
                        }
                        .day-name{
                            font-size: 12px;
                            line-height: 18px;
                            color: #9F9F9F;
                        }
                        .day-date{
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 21px;
                            color: #4E4E4E;
                            
                        }
                    }
                }

                .previous, .next{
                    .fa{
                        color: #231D1D;
                    }
                }
            }
        }
        .list-group{
            .list-item{
                margin-bottom: 1rem;
                box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
                border-radius: 5px;
                padding: 1rem;
                display: flex;
                
                .details-container{
                    flex: 0 70%;
                    display:flex;
                    .profile-pic{
                        padding-right: 1.5rem;
                        img{
                            border-radius: 50%;
                            height: 125px;
                            width: 125px;
                            object-fit: cover;
                        }
                        .prof-feature{
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 21px;
                            color: #676767;
                            margin-top: 1rem;
                            .feature{
                                margin-bottom: 5px;
                                display: flex;
                                align-items: center;
                                .consultation-icon{
                                    border-radius: 0;
                                    width: 21px;
                                    margin-right: 5px;
                                    margin-bottom: 0;
                                    height: auto;
                                }
                            }
                        }
                    }
                    .details{
                        width: 100%;
                        padding-right: 1rem;
                        .name-row{
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            .name{
                                font-weight: 600;
                                font-size: 20px;
                                line-height: 30px;
                                color: #4E4E4E;
                                margin: 3px 0;
                                text-decoration: none;
                                &:hover{
                                    color: #1b1b1b;
                                }
                                
                            }
                            .favourite-icon{
                                cursor: pointer;
                                img{
                                    width: 20px;
                                }
                            }
                        }
                        .specialty{
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 24px;
                            color: #4E4E4E;
                            margin: 3px 0;
                        }
                        .feature-tags{
                            display: flex;
                        }
                        .feature-tag{
                            border-radius: 5px;
                            color: white;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 21px;
                            padding: 2px 5px;
                            display: inline-flex;
                            margin: 3px 0;
                            margin-right: 5px;
                            &.online-booking{
                                background: #346FF1;
                            }
                            &.corporate-panel{
                                background: #FD9B5B;
                            }
                        }
                        .address-container{
                            display: flex;
                            margin: 3px 0;
                            line-height: 27px;

                            .location-pin-outline-icon{
                                width: 30px;
                            }
                            
                            .address-col{
                                font-size: 18px;
                                color: #4E4E4E;
                                width: 75%;
                                .address-name{
                                    font-size: 18px;
                                    color: #4E4E4E;
                                    font-weight: 600;
                                    text-decoration: none;
                                    &:hover{
                                        color: #1b1b1b;
                                    }
                                }
                                .address{
                                    font-weight: normal;
                                }
                            }
                        }
                        .contact-container{
                            display: flex;
                            margin-top: 10px;
                            justify-content: space-between;

                            .phone-email-container{
                                a{
                                    &:first-child{
                                        margin-right: 5px;
                                    }
                                }
                                .contact-icon{
                                    max-height: 45px;
                                    max-width: 45px;
                                }
                            }
                            .book-online-btn{
                                background: #F25C54;
                                border-radius: 30px;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 15px;
                                line-height: 22px;
                                text-align: center;
                                color: #FFFFFF;
                                text-decoration: none;
                                align-items: center;
                                display: flex;
                                padding: 10px 17px;
                            }
                        }
                    }
                }

                .vertical-line{
                    border-left: 1px solid #9F9F9F;
                }

                .slots-loader{
                    display: flex;
                    justify-content: center;
                    flex: 1 1 30%;
                    align-items: center;
                }

                .time-container{
                    flex: 1 30%;
                    padding: 0 1rem;
                    flex-direction: column;
                    display: flex;
                    justify-content: space-between;

                    .empty-slots{
                        align-items: center;
                        display: flex;
                        justify-content: center;
                        height: 100%;
                    }

                    .time-slots{
                        display:flex;
                        justify-content: center;
                        .time-slots-col{
                            flex: 1 33.33%;
                            text-align: center;
                            flex-direction: column;
                            display: flex;
                            justify-content: space-around;
                            .slot{
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 21px;
                                text-align: center;
                                border-radius: 5px;
                                padding: 3px 10px;
                                margin: 5px 0.5rem;
                                &.valid-slot{
                                    background: #51B848;
                                    color: #FFFFFF;
                                    white-space: nowrap;
                                    &:hover{
                                        background: #28a745;
                                    }
                                }
                            }
                        }
                    }
                    .availability-row{
                        margin-top: 1rem;
                        .availability-btn{
                            background: #FFFFFF;
                            border: 2px solid #F25C54;
                            border-radius: 30px;
                            font-weight: 500;
                            font-size: 15px;
                            line-height: 22px;
                            text-align: center;
                            color: #F25C54;
                            display: flex;
                            padding: 10px 25px;
                            text-decoration: none;
                            display: flex;
                            justify-content: center;
                        }
                    }
                }
            }
        }

        .map-content{
            display: flex;
            flex-direction: row;
            height: 500px;
            margin-bottom: 1rem;

            .result-list{
                margin-right: 1rem;
                overflow-y: scroll;
                width: 58%;

                .map-result-item{
                    width: 375px;
                    background: #F9F9F9;
                    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
                    border-radius: 5px;
                    margin: 5px 15px 15px 15px;
                    padding: 1rem;
                    .map-result-item-container{
                        .map-result-item-line{
                            margin: 5px 0;
                        }
                        .name{
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 30px;
                            color: #4E4E4E;
                        }
                        .specialty{
                            font-size: 16px;
                            line-height: 24px;
                            color: #4E4E4E;
                        }
                        .feature-tag{
                            border-radius: 5px;
                            color: white;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 21px;
                            padding: 2px 5px;
                            display: inline-flex;
                            &.online-booking{
                                background: #346FF1;  
                            }
                            &.corporate-panel{
                                background: #FD9B5B;
                            }
                        }
                        .availability-btn{
                            background: #FFFFFF;
                            border: 2px solid #F25C54;
                            border-radius: 30px;
                            font-weight: 500;
                            font-size: 15px;
                            line-height: 22px;
                            text-align: center;
                            color: #F25C54;
                            display: flex;
                            padding: 10px 70px;
                        }
                    }
                }
            }
            .map-container{
                width: 100%;
                height: 500px;
            }
        }

    }
    .no-result-div{
        padding-left: 3rem;
        padding-right: 3rem;
    }
    .contact-us-here{
        color: #f05450;
        text-decoration: none;
        &:hover{
            color: #f51913;
        }
    }
}

.filter-modal{
    .form-container{
        padding: 2rem;
        .MuiFormControl-root{
            width: 100%;
            margin-top: 16px;
            margin-bottom: 8px;
        }
    }
}

@media (max-width: $breakpoint-tablet) {
    .result-page {
        .main-section{
            background: none;

            .container{
                padding-top: 150px;
                padding-bottom: 20px;
                .search-bar{
                    .section-form{
                        flex-direction: column;
                        .location-group, .speciality-group{
                            margin-right: 0;
                        }
                    }
                }
            }
        }
        .result-list-container{
            .container-lg{
                padding: 0;
            }
            .map-content{
                position: relative;
                .result-list{
                    display: flex;
                    width: 100%;
                    position: absolute;
                    z-index: 1;
                    bottom: 0;
                }
            }
            .list-header{
                display: none;
            }
            .list-group{
                .list-item{
                    margin: 0.5rem 1rem;
                    .details-container{
                        flex: 1 0 100%;

                        .profile-pic{
                            padding-right: 15px;
                            img{
                                width: 67px;
                                height: 67px;
                            }
                            .prof-feature{
                                font-size: 10px;
                                line-height: 15px;
                                .feature{
                                    .consultation-icon{
                                        width: 18px;
                                        margin-right: 5px;
                                    }
                                }
                            }
                        }

                        .details{
                            .name-row{
                                .name{
                                    font-weight: 500;
                                    font-size: 14px;
                                    line-height: 21px;
                                    color: #231D1D;
                                }
                            }
                            .specialty{
                                font-weight: normal;
                                font-size: 12px;
                                line-height: 18px;
                                color: #676767;
                            }
                            .feature-tag{
                                font-weight: 600;
                                font-size: 10px;
                                line-height: 15px;
                            }
                            .address-container{
                                .address-col{
                                    .address-name{
                                        font-weight: normal;
                                        font-size: 12px;
                                        line-height: 18px;
                                    }
                                    .address{
                                        font-size: 12px;
                                        line-height: 18px;
                                    }
                                }
                                .location-pin-outline-icon{
                                    width: 18px;
                                }
                            }
                            .contact-container{
                                .book-online-btn{
                                    font-size: 15px;
                                    line-height: 22px;
                                }
                            }
                        }
                    }
                    .vertical-line{
                        display: none;
                    }
                    .time-container{
                        display: none;
                    }
                }
            }

            .filter-bar{
                .left-filters{
                    display: none;
                }
                .mobile-filters{
                    display: flex;
                    img{
                        width: 25px;
                        height: 25px;
                    }
                }
            }
        }

        .MuiPagination-root{
            .MuiPagination-ul{
                margin: 1.5rem auto 1.5rem auto;
            }
        }
    }
}

@media (max-width: $breakpoint-large-phone) {
    .result-page {
        .main-section{
            .container{
                .main-title{
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 21px;
                    text-align: center;
                    letter-spacing: 0.05em;
                    text-transform: uppercase;
                    color: #4E4E4E;
                }
                .main-sub-title{
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 18px;
                    text-align: center;
                    color: #4E4E4E;
                }
            }
        }
    }
}