@import "global_var";

.booking-modal{
    .modal-body{
        max-width: 700px !important;
    }
    .slot-error{
        margin-top: -20px;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 20px;
        color: #F25C54;
    }
    .title{
        font-weight: 600;
        font-size: 30px;
        line-height: 45px;
        text-align: center;
        color: #231D1D;
        margin-bottom: 45px;
    }
    .booking-form{
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 27px; 
        color: #231D1D;
        .row-inline{
            margin-bottom: 20px;
            align-items: center;
            .form-label{
                flex: 1 0 40%;
                text-align: left;
            }
            .ins-eligibility{
                text-align: left;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
                color: #676767;
            }
            .insurance-field{
                margin-bottom: 15px;
            }
            div[class*="-container"], .right-div, .note-textarea{
                flex: 1 0 50%;

                div[class*="-control"]{
                    padding: 5px;
                    border-radius: 30px;
                    border: 1px solid #D1D1D1;
                    box-shadow: none !important;
                    
                    span[class*="-indicatorSeparator"]{
                        background-color: transparent;
                    }
        
                    div[class*="-placeholder"]{
                        color: #9F9F9F;
                    }
                }
                div[class*="-menu"]{
                    text-align: left;
                }
            }

            .note-textarea{
                background: #FFFFFF;
                border: 1px solid #B5B5B5;
                border-radius: 15px;
                resize: none;
            }
        }

        .someone-else-form{
            text-align: left;
            padding: 0rem 1rem 1rem 1rem;

            .form-group{
                width: 48%;
                .form-control, div[class*="-control"]{
                    border-radius: 30px;
                    border: 1px solid #D1D1D1;
                    box-shadow: none !important;
                   color: #231D1D;
                   &.is-invalid{
                        border-color: #dc3545;
                   }
                }
                .dob-error{
                    width: 100%;
                    margin-top: 0.25rem;
                    font-size: 80%;
                    color: #dc3545;
                }

                span[class*="-indicatorSeparator"]{
                    background-color: transparent;
                }
    
                div[class*="-placeholder"]{
                    color: #9F9F9F;
                }
            }
            .form-row{
                justify-content: space-evenly;
            }

            .dob-field-div{
                display: flex;
                justify-content: space-between;

                .dob-field{
                    width: 32%;
                }
            }
        }
    }
    .t-and-c{
        font-size: 11px;
    }

    .booking-information-container{
        display: flex;
        flex-direction: column;
        align-items: center;

        .booking-information{
            text-align: left;
            max-width: 500px;
            width: 100%;
            .div-title{
                font-weight: 600;
                font-size: 18px;
                line-height: 27px;
                color: #231D1D;
                margin-bottom: 15px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .edit-icon{
                    width: 20px;
                    height: 20px;
                    cursor: pointer;
                }

                .appointment-status{
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    text-align: right;
                    &.cancelled{
                        color: #F25C54;
                    }
                    &.attended{
                        color: #51B848;
                    }
                }
            }

            .doc-information{
                .details-container{
                    flex: 0 70%;
                    display:flex;
                    margin-bottom: 15px;
                    align-items: center;
                    .profile-pic{
                        padding-right: 35px;
                        img{
                            border-radius: 50%;
                            height: 85px;
                            width: 85px;
                            object-fit: cover;
                        }
                    }
                    .details{
                        width: 100%;
                        padding-right: 1rem;
                        .name{
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                            color: #231D1D;
                        }
                        .specialty{
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 21px;
                            color: #676767;
                        }
                        .clinic-row{
                            display: flex;
                            img{
                                width: 18px;
                                height: 100%;
                                margin-right: 10px;
                            }
                            .clinic-name{
                                font-weight: normal;
                                font-size: 14px;
                                line-height: 21px;
                                color: #676767;
                            }
                        }
                    }
                }
            }
        }

        .appointment-container{
            .appointment-details{
                display: flex;
                align-items: end;
                img{
                    margin-right: 15px;
                    width: 20px;
                    height: 20px;
                }
                .appointment-date{
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 21px;
                    color: #676767;
                }
            }
        }

        .patient-container{
            .patient-details{
                .row-info{
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 10px;
                    .col-label{
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 21px;
                        color: #676767;
                    }
                    .col-value{
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 21px;
                        text-align: right;
                        color: #676767;
                    }
                }
                .row-note{
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                    color: #676767;
                }
            }
        }
    }

    .next-btn, .submit-btn{
        background: #F25C54;
        border-radius: 30px;
        max-width: 210px;
        width: 100%;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        color: #FFFFFF;
        border-color: #F25C54;
        padding-top: 10px;
        padding-bottom: 10px;

        margin: 40px auto 0 auto;
        &:active{
            background: #F25C54 !important;
            border-color: #F25C54 !important;
        }
        &:hover{
            background: #F25C54 !important;
            border-color: #F25C54 !important;
            color: white !important;
        }
    }

    .booking-disclaimer{
        margin-top: 15px;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: #676767;
        margin-bottom: 50px;
    }

    .confirmed-container{
        .success-img{
            margin-bottom: 25px;
            img{
                width: 84px;
            }
        }
        .success-title{
            font-weight: 600;
            font-size: 25px;
            line-height: 26px;
            text-align: center;
            letter-spacing: 0.5px;  
            color: #51B848;
            margin-bottom: 35px;
        }
        .appointment-detail{
            .div-title{
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;
                text-align: center; 
                color: #231D1D;
                margin-bottom: 15px;
            }
            .slot-detail, .location-detail{
                display: flex;
                align-items: center;
                width: 100%;
                max-width: 250px;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 20px;
                img{
                    width: 25px;
                    height: 25px;
                    margin-right: 15px;
                }
                .detail-div{
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 21px;
                    color: #676767;
                }
            }
        }
        .features{
            display: flex;
            justify-content: space-evenly;

            .link-container{
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 15px 0;
                cursor: pointer;
                text-decoration: none;

                .interest-icon{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    height: 85px;
                    width: 85px;
                    background: #FFFFFF;
                    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
                    border-radius: 32px;
                    &:hover{
                        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
                    }
                    img{
                        width: 45px;
                        &.calendar-add-icon{
                            margin-left: 5px;
                        }
                    }
                    .fa-ban{
                        font-size: 40px;
                        color: #f25c54;
                    }
                    
                }
                .interest-title{
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 18px;           
                    color: #676767;
                    padding-top: 15px;
                }
            }
        }

        .booking-disclaimer{
            text-align: left;
            max-width: 580px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .slot-selection-container{
        .slots-container{
            .date-bar{
                padding: 10px 15px;
                display: flex;
                justify-content: space-between;
                background: #F9F9F9;
                align-items: center;
                margin-bottom: 1rem;
                .btn-link{
                    color: black;
                    &.disabled{
                        background-color: transparent !important;
                        color: #6c757d;
                    }
                    &:focus{
                        outline: 0 !important;
                        box-shadow: none !important;
                    }
                }
                .date{
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #676767;
                }
            }
            .slots{
                display: flex;
                flex-wrap: wrap;
                .slots-loader{
                    width: 100%;
                    text-align: center;
                    margin: 1rem 0;
                    .spinner{
                        color: #676767;
                    }
                }
                .slot{
                    width: 100px;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 27px;
                    text-align: center;
                    border-radius: 5px;
                    padding: 10px;
                    margin: 5px 5px;
                    &.valid-slot{
                        background: #51B848;
                        color: #FFFFFF;
                        white-space: nowrap;
                        &:hover{
                            background: #28a745;
                        }
                    }
                }
                .empty-div{
                    text-align: center;
                    font-weight: 500;
                    color: #231D1D;
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: $breakpoint-large-phone) {
    .booking-modal{
        .booking-form{
            padding: 0rem 1rem 1rem 1rem;
            .row-inline{
                flex-direction: column;
                align-items: flex-start;

                div[class*="-container"]{
                    flex: 100%;
                    width: 100%;
                }
            }
            .someone-else-form{
                padding: 0;
                .form-group{
                    width: 100%;
                }
            }
        }
        .submit-btn, .next-btn{
            max-width: 100%;
        }

        .confirmed-container{
            .success-title{
                font-size: 23px;
                line-height: 21px;
            }
            .features{
                .link-container{
                    .interest-title{
                        font-size: 10px;
                    }
                }
            }
        }

        .slot-selection-container{
            .slots-container{
                .slots{
                    justify-content: space-evenly;
                }
            }
            .title{
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #231D1D;
                margin-bottom: 30px;
            }
        }
    }
}

@media (max-width: $breakpoint-medium-phone) {
    .booking-modal{
        .slot-selection-container{
            .slots-container{
                .slots{
                    padding: 0;
                }
            }
        }
    }
}