@import "global_var";

.settings-page{
    margin-bottom: 50px;
    .header{
        margin-top: 50px;
        margin-bottom: 50px;
        color: black;
        text-align: center;
        .title{
            font-weight: 600;
            font-size: 36px;
            line-height: 54px;
            color: #231D1D;
            margin-bottom: 1rem;
        }
        .bread-crumb{
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #231D1D;
            margin-bottom: 1rem;
        }
    }
    .header-line{
        border: 1px solid #231D1D;
        margin-bottom: 50px;
    }
    .body{
        .section-div{
            .div-title{
                font-weight: 600;
                font-size: 22px;
                line-height: 33px;
                color: #231D1D;
                margin-bottom: 20px;
            }
            .submit-btn{
                background: #F25C54;
                border-radius: 30px;
                font-weight: 500;
                font-size: 15px;
                line-height: 22px;
                text-align: center; 
                color: #FFFFFF;
                max-width: 300px;
                width: 100%;
                border-color: #F25C54;
                padding-top :8px;
                padding-bottom: 8px;
                margin-left: -5px;
                margin-top: 0.5rem;
            }
        }
        .profile-div{
            .profile-details{
                display: flex;
                flex-direction: column;

                flex-wrap: nowrap;
                width: 100%;
                font-size: 16px;
                line-height: 24px;
                color: #676767;
                margin-bottom: 2rem;
                
                .info-row{
                    display: flex;
                    font-weight: normal;
                    margin-bottom: 10px;
                    .info-title{
                        width: 65px;
                    }
                    .semicolon{
                        width: 20px;
                    }
                    .info-value{
                        font-weight: 600;
                    }
                }
            }
            .profile-policy{
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                color: #676767;
            }
        }
        .body-line{
            margin-top: 50px;
            margin-bottom: 50px;
            border-top: 1px solid #4E4E4E;
        }

        .password-form{
            .form-label{
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                color: #676767;
            }
            .form-control{
                background: #FFFFFF;
                border: 1px solid #D1D1D1;
                border-radius: 30px;
                height: 40px;
                &.is-invalid{
                    border-color: #dc3545;
                }
            }
            .form-row{
                max-width: 300px;
                width: 100%;
                .form-group{
                    width: 100%;
                    margin-bottom: 25px;
                }
            }
        }
        .change-pw-success{
            max-width: 350px;
            width: 100%;
            margin-bottom: 1rem;
        }

        .premium-div{
            .premium-employer{
                display: flex;
                align-items: center;
                .premium-img{
                    margin-right: 75px;
                    img{
                        max-width: 250px;
                        width: 100%;
                    }
                }
                .premium-details{
                    .premium-line{
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        color: #676767;
                        margin-bottom: 25px;
                        .premium-name{
                            font-weight: 500;
                        }
                    }
                    .premium-btn{
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 22px;
                        text-align: center;
                        color: #F25C54;
                        background: #FFFFFF;
                        border: 3px solid #F25C54;
                        border-radius: 30px;
                        padding: 10px 30px;
                    }
                }
            }
            .premium-form{
                .form-group{
                    width: 100%;
                    margin-bottom: 25px;
                }
                .form-row{
                    max-width: 300px;
                    width: 100%;
                }
                .form-control{
                    background: #FFFFFF;
                    border: 1px solid #D1D1D1;
                    border-radius: 30px;
                    height: 40px;
                }
                .code-field{
                    background-color: #f8f9fa;
                    border: 1px solid #ccc;
                    border-radius: .3rem;
                    font-size: 14px;
                    margin: .25rem;
                    height: 40px;
                    outline: none;
                    text-align: center;
                    transition-duration: .25s;
                    width: 40px;
                }
                swd-pin-field[completed] {
                    .code-field {
                        &.incorrect-pin{
                            border-color: #f5c6cb;
                            background-color: #f8d7da;
                        }
                        &.correct-pin{
                            border-color: #28a745;
                            background-color: rgba(40,167,69,.1);
                        }
                    }   
                } 
                .invalid-pin-error{
                    width: 100%;
                    margin-top: 0.25rem;
                    font-size: 80%;
                    color: #dc3545;
                    display: none;

                    &.show{
                        display: block;
                    }
                }

                .premium-error{
                    width: 100%;
                    margin-top: 0.25rem;
                    font-size: 80%;
                    color: #dc3545;
                    display: none;
                }
            }
        }
    }
}

@media (max-width: $breakpoint-tablet) {
    .settings-page{
        .body{
            .premium-div{
                .premium-employer{
                    .premium-img {
                        margin-right: 20px;
                    }
                }
            }
        }
    }
}

@media (max-width: $breakpoint-large-phone) {
    .settings-page{
        .body{
            .premium-div{
                .premium-employer{
                    flex-direction: column;
                    .premium-img{
                        margin-right: 0;
                        margin-bottom: 1.5rem;
                    }
                    .premium-details{
                        text-align: center;
                    }
                }

                .premium-form{
                    .form-row{
                        margin: auto;
                    }
                }
            }
            .section-div{
                .div-title{
                    text-align: center;
                }
            }
            .password-form{
                .form-row{
                    margin: auto;
                }
            }

            .save-btn-div{
                text-align: center;
            }
        }
    }
}