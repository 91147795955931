@import "global_var";

.header{
    margin-top: 50px;
    margin-bottom: 50px;
    color: black;
    text-align: center;
    .title{
        font-weight: 600;
        font-size: 36px;
        line-height: 54px;
        color: #231D1D;
        margin-bottom: 1rem;
    }
    .bread-crumb{
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #231D1D;
        margin-bottom: 1rem;
    }
    .header-anchor{
        color: #231D1D;
    }
}
.header-line{
    border: 1px solid #231D1D;
}

.expert-column-page {
    .body{
        display: flex;
        flex-wrap: wrap;
        .loader{
            width: 100%;
        }
        .card{
            border: none;
            width: 30%;
            margin: 1rem;
            a{
                text-decoration: none;
                cursor: pointer;
                color: #4e4e4e;
            }
            .card-img-top{
                height: 250px;
                object-fit: cover;
            }
            .card-body{
                background-color: #f5f5f5;
                height: 190px;

                .card-title{
                    color: #4e4e4e;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 24px;

                    a{
                        &:hover{
                            color: #EC2727;
                        }
                    }
                }

                .author-date{
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 20px;
                    color: #BDBDBD;
                    margin-bottom: 10px;
                }

                .card-text{
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    display: -webkit-box;
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }

        .empty-div{
            padding: 5rem;
            text-align: center;
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            color: #231D1D;
        }
    }
    .MuiPagination-ul{
        margin: 1rem 0 2rem 0;
    }
}

.expert-column-detail-page{
    .details-div{
        margin-top: 3rem;
        margin-bottom: 3rem;
        .author-date{
            font-weight: normal;
            font-size: 14px;
            line-height: 26px;
            color: #BDBDBD;
            margin-bottom: 10px;
        }
        .image{
            margin-bottom: 3rem;
            width: 100%;
            text-align: center;
            img{
                max-width: 100%;
            }
        }
    }
    .body{
        .empty-div{
            padding: 5rem;
            text-align: center;
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            color: #231D1D;
        }
    }
}

@media (max-width: $breakpoint-laptop) {
    .expert-column-page{
        .body{
            .card{
                width: 45%;
            }
        }
    }
}

@media (max-width: $breakpoint-tablet) {
    .expert-column-page{
        .body{
            .card{
                width: 100%;
            }
        }
    }
}