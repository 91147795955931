@import "global_var";

.main-modal{
    /* Change the white to any color */
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    .modal-content{
        align-items: center;

        .close-modal-btn{
            z-index: 1;
            position: absolute;
            right: -18px;
            top: -18px;
            background: #F25C54;
            border-radius: 25px;
            color: white;
            padding: 3px 8px;
            i{
                -webkit-text-stroke: 5px #F25C54;
                font-size: 27px;
            }
        }

        .modal-body{
            text-align: center;
            padding: 75px 15px;
            width: 100%;
            max-width: 600px;

            .modal-logo{
                margin-bottom: 50px;
                img{
                    width: 217px;
                }
            }

            .modal-title{
                font-weight: 600;
                font-size: 30px;
                line-height: 45px;
                text-align: center;
                color: #231D1D;
                margin-bottom: 5px;
            }
            .modal-sub-title{
                font-weight: normal;
                font-size: 18px;
                line-height: 27px;
                text-align: center;     
                color: #545454;
                margin-bottom: 35px;
            }

            .t-and-c{
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
                text-align: center;
                color: #676767;
                margin-bottom: 25px;
                a{
                    color: #F25C54;
                    text-decoration: none;
                    &:hover{
                        color: rgb(236, 39, 39);
                    }
                }
            }

            .fb-btn{
                background: #1A77F2;
                border-radius: 30px;
                border: none;
                font-weight: 600;
                font-size: 15px;
                line-height: 22px;
                color: #FFFFFF;
                padding-top: 12px;
                padding-bottom: 12px;
                margin-bottom: 50px;
                img{
                    margin-right: 15px;
                }
            }

            .signin-btn, .change-pw-btn{
                background: #D7D7D7;
                border-radius: 30px;
                border: none;
                font-weight: 500;
                font-size: 15px;
                line-height: 22px;
                text-align: center;
                color: #FFFFFF;
                padding-top: 12px;
                padding-bottom: 12px;
                &:hover{
                    background:#CCCCCF;
                }
            }

            .new-patient-div{
                font-weight: normal;
                font-size: 18px;
                line-height: 27px;
                text-align: center;
                letter-spacing: 0.428571px;
                color: #1E1E1E;
                .sign-up-link{
                    color: #F25C54;
                    text-decoration: none;
                    padding: 0;
                }
            }
        }
    }
}

@media (max-width: $breakpoint-992) {

    .main-modal{
        &.modal-dialog{
            margin: 0;
            height: 100%;
            width: 100%;
            max-width: 100%;
        }

        .modal-content{
            min-height: 100%;
            .modal-body{
                padding: 35px 15px;
            }
            .close-modal-btn{
                right: 0;
                top: 0;
                color: #F25C54;
                background: white;
                i{
                    -webkit-text-stroke: 5px white;
                }
            }
        }
    }
}