
    $class-prefix: "custom-icon--";
    $sprite-image-url: "../images/partner-sprite-logos.png";
    $sprite-grid-size: 86px;
    
    $icon-list: (
        ("berjaya", -87, 0),
        ("swiss-garden", -87, -86),
        ("gtower", -1, -86),
        ("airbnb", -1, -171),
        ("agoda", -1, 0)
    );
    
    [class*='#{$class-prefix}'] {
        display: inline-block;
        width: $sprite-grid-size;
        height: $sprite-grid-size;
    
        line-height: $sprite-grid-size;
    
        background-image: url(#{$sprite-image-url});
        background-repeat: no-repeat;
        margin: 10px;
    }

    .#{$class-prefix}berjaya{
        background-position: -87px 0;
    }

    .#{$class-prefix}swiss-garden{
        background-position: -87px -86px;
    }

    .#{$class-prefix}gtower{
        background-position: -1px -86px;
    }

    .#{$class-prefix}airbnb{
        background-position: -1px -171px;
    }

    .#{$class-prefix}agoda{
        background-position: -1px 0;
    }
    
.hotel-div{
    padding: 5rem;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #231D1D;
}

