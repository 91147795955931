.sign-in-modal{
    //max-width: 700px !important;


    .input-icon {
        width: 35px;
    }

    .login-error{
        color:#f05450;
        margin-bottom: 10px;
    }

    &.change-pw-modal{
        .change-pw-form{
            width: 100%;
            .form-group{
                width: 100%;
            }
            .form-row{
                margin-bottom: 2rem;
            }
            .form-label{
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                color: #676767;
            }
            .form-control{
                background: #FFFFFF;
                border: 1px solid #D1D1D1;
                border-radius: 30px;
                height: 40px;
            }
        }
        .change-pw-success{
            font-weight: normal;
            font-size: 18px;
            line-height: 27px;
            text-align: center;
            color: #545454;
            .reset-change-pw{
                cursor: pointer;
                color:#f05450;
                text-decoration: none;
                &:hover{
                    color: #1E1E1E;
                }
            }
        }
    }

    .modal-content{
        .modal-body{

            .form-container{
                display: flex;
                align-items: center;
                width: 100%;
                flex-direction: column;
            }

            .login-form{
                width: 90%;
                .MuiGrid-container{
                    font-family: Poppins;
                    flex-wrap: nowrap;
                    border: 1px solid #B5B5B5;
                    border-radius: 42.5px;
                    padding: 0 1rem 1rem 1rem;

                    .grid-input-icon{
                        padding-right: 10px;
                    }
                    .grid-input-field{
                        width: 100%;
                    }
                    .form-control{
                        background-color: transparent;
                        height: auto !important;
                    }
                    .MuiFormLabel-root{
                        color: #B5B5B5;
                    }
                    .MuiInputBase-input{
                        font-style: normal;
                        font-weight: 500;
                        line-height: 30px;
                        letter-spacing: 0.4px;
                        color: #231D1D;
                    }
                }
            }

            .forget-password{
                font-weight: normal;
                font-size: 18px;
                line-height: 27px;
                text-align: center;
                letter-spacing: 0.428571px;
                color: #F25C54;
                margin-bottom: 35px;
                &:hover{
                    color: rgb(236, 39, 39);
                }
            }

            .separator {
                display: flex;
                align-items: center;
                text-align: center;
                font-weight: normal;
                font-size: 18px;
                line-height: 27px;
                color: #545454;
                padding: 30px 0;
            }
            .separator::before,
            .separator::after {
                content: '';
                flex: 1;
                border-bottom: 1px solid #9F9F9F;
            }
              
            .separator:not(:empty)::before {
                margin-right: 1rem;
            }
              
            .separator:not(:empty)::after {
                margin-left: 1rem;
            }
            .fb-btn{
                width: 90%;
            }

        }
    }
}