.verify-phone-modal{
    .title{
        font-weight: 600;
        font-size: 30px;
        line-height: 45px;
        text-align: center;
        color: #231D1D;
        margin-bottom: 45px;
    }

    .verify-current-phone{
        display: flex;
        flex-direction: column;
        align-items: center;
    
        .phone-number{
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 27px;
            color: #231D1D;
            margin-bottom: 45px;
        }
    }

    .next-btn, .submit-btn{
        background: #F25C54;
        border-radius: 30px;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        color: #FFFFFF;
        width: 80%;
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 0 auto;
        border-color: #F25C54;
        &:hover{
            background: #f22;
            color: white;
            border-color: #F25C54;
        }
        &.disabled{
            // pointer-events: none;
            // opacity: .35;
            border-color: transparent;
        }
    }

    .form-row{
        justify-content: center;
        margin-bottom: 35px;

        .form-group{
            width: 100%;
            text-align: left;

            .form-control{
                background: #FFFFFF;
                border: 1px solid #D1D1D1;
                border-radius: 30px;

                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                color: #676767;
                padding: 20px;
                &.is-invalid{
                    border-color: #dc3545;
                }
            }
        }

        .phone-field-div{
            display: flex;
            max-width: 350px;
            width: 100%;
            .phone-prefix-field{
                flex: 1 0 25%;
                margin-right: 20px;
        
                div[class*="-control"]{
                    border-radius: 30px;
                    border: 1px solid #D1D1D1;
                    box-shadow: none !important;
                    
                    span[class*="-indicatorSeparator"]{
                        background-color: transparent;
                    }
        
                    div[class*="-placeholder"]{
                        color: #9F9F9F;
                    }
                }
                div[class*="-menu"]{
                    text-align: left;
                    width: 250px !important;
                }
            }
            .phone-input-div{
                flex: 1 0 70%;
            }
        }
    }

    .pin-step-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        .pin-field{
            background-color: #f8f9fa;
            border: 1px solid #ccc;
            border-radius: .3rem;
            font-size: 2rem;
            margin: .25rem;
            height: 3.5rem;
            outline: none;
            text-align: center;
            transition-duration: .25s;
            width: 3rem;
        }

        .invalid-pin-error{
            display: none;
            margin-top: 0.25rem;
            font-size: 80%;
            color: #dc3545;

            &.show{
                display: block;
            }
        }

        swd-pin-field[completed] {
            .pin-field {
                &.incorrect-pin{
                    border-color: #f5c6cb;
                    background-color: #f8d7da;
                }
                &.correct-pin{
                    border-color: #28a745;
                    background-color: rgba(40,167,69,.1);
                }
            }   
        } 
        
        .submit-btn{
            margin-top: 45px;
            &.disabled{
                background-color: #F25C54;
            }
        }
    }
}
