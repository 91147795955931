.sign-up-modal{
    //max-width: 700px !important;
    div[class*="-menu"]{
        width: 250px !important;
    }
    .modal-content{
        .modal-body{
            .fb-btn{
                margin-bottom: 30px;
            }
            .signin-btn{
                margin-bottom: 50px;
            }

            .dob-field-div{
                display: flex;

                .dob-field{
                    flex: 1 1 30%;
                    margin: 10px;

                    div[class*="-control"]{
                        border-radius: 30px;
                        border: 1px solid #D1D1D1;
                        box-shadow: none !important;
                        
                       // color: #231D1D;
                    }
                    span[class*="-indicatorSeparator"]{
                        background-color: transparent;
                    }

                    div[class*="-placeholder"]{
                        color: #9F9F9F;
                    }
                }
            }
        
            .dob-step{
                .dob-field-div{    
                    .dob-field{
                        div[class*="-control"]{
                            font-size: 20px;
                            line-height: 30px;
                            letter-spacing: 0.4px;
                            color: #9F9F9F;
                            padding: 0.5rem 10px;
                        }
                    }
                }
                .dob-error{
                    color: red;
                    text-align: left;
                    margin-left: 10px;
                    font-weight: normal;
                    font-size: 14px;
                }
                .next-btn{
                    background: #D7D7D7;
                    border-radius: 30px;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 22px;
                    text-align: center;
                    color: #FFFFFF;
                    padding-top: 12px;
                    padding-bottom: 12px;
                    margin-top: 50px;
                }
            }

            .registration-step{
                align-items: center;
                display: flex;
                flex-direction: column;
                .sign-up-form{
                    width: 80%;
                    .form-row{
                        .form-label{
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 21px;
                            color: #676767;
                        }
                        .form-group{
                            width: 100%;
                            text-align: left;

                            .form-control{
                                background: #FFFFFF;
                                border: 1px solid #D1D1D1;
                                border-radius: 30px;

                                font-weight: normal;
                                font-size: 16px;
                                line-height: 24px;
                                color: #676767;
                                padding: 20px;
                                &.is-invalid{
                                    border-color: #dc3545;
                                }
                            }
                        }
                    }
                    .submit-btn{
                        background: #D7D7D7;
                        border-radius: 30px;
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 22px;
                        text-align: center;
                        color: #FFFFFF;
                        border-color: #D7D7D7;
                        padding-top: 12px;
                        padding-bottom: 12px;
                    }

                    .dob-field-div{ 
                        margin-bottom: 0; 
                        .dob-field{
                            margin-top: 0;
                            margin-bottom: 0;
                            &:first-child{
                                margin-left: 0;
                            }
                            &:last-child{
                                margin-right: 0;
                            }
                            div[class*="-control"]{
                                font-size: 20px;
                                line-height: 30px;
                                letter-spacing: 0.4px;
                                color: #231D1D;
                            }
                        }
                    }
                    .dob-error{
                        margin-bottom: 1rem;
                        margin-top: 0.25rem;
                        font-size: 80%;
                        color: #dc3545;
                    }

                    .gender-field{    
                        div[class*="-control"]{
                            border-radius: 30px;
                            border: 1px solid #D1D1D1;
                            box-shadow: none !important;
                            
                            span[class*="-indicatorSeparator"]{
                                background-color: transparent;
                            }
        
                            div[class*="-placeholder"]{
                                color: #9F9F9F;
                            }
                        }
                    }
                    .phone-field-div{
                        display: flex;
                        .phone-prefix-field{
                            flex: 1 0 25%;
                            margin-right: 20px;

                            div[class*="-control"]{
                                border-radius: 30px;
                                border: 1px solid #D1D1D1;
                                box-shadow: none !important;
                                
                                span[class*="-indicatorSeparator"]{
                                    background-color: transparent;
                                }
            
                                div[class*="-placeholder"]{
                                    color: #9F9F9F;
                                }
                            }
                        }
                        .phone-input-div{
                            flex: 1 0 70%;
                        }
                    }
                    .invalid-field{
                        border-color: #dc3545 !important;
                    }
                }
            }
        }
    }
}