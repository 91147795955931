.terms-page{
    .header{
        margin-top: 50px;
        margin-bottom: 50px;
        color: black;
        text-align: center;
        .title{
            font-weight: 600;
            font-size: 36px;
            line-height: 54px;
            color: #231D1D;
            margin-bottom: 1rem;
        }
        .bread-crumb{
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #231D1D;
            margin-bottom: 1rem;
        }
    }
    .header-line{
        border: 1px solid #231D1D;
        margin-bottom: 50px;
    }

    .body{
        p{
            color: #676767;
        }
        .terms__list{
            padding: 1rem;
            a{
                color: #f05450;
            }
            li{
                margin-bottom: 2.5rem;
            }
            li::marker{
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 27px;
                    color: #676767;
            }
        }
        h3{
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            color: #676767;
        }
        h4{
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #676767;
        }
    }
}