
@import "global_var";

/* Main .footer */
$footer-margin: 0px 70px;
$footer-padding: 0px 50px;

.footer{
	background-color: #4e4e4e;
	color: white !important;
	padding: 0px !important;

	.footer-title{
		font-size: 14px;
		letter-spacing: 1px;
		font-weight: 800;
		margin-bottom: 15px;
	}
	.footer-list{
		list-style: none;
		padding-left: 0;
		li{
			font-size: 12px;
			line-height: 16px;

			&:not(:first-child) {
				margin-top: 9px;
				padding-top: 10px;
			}
			
			a{
				color: white;
			}
		}
	}
	.footer-row-content{
		margin: $footer-margin;
		padding: 50px 50px 15px 50px; 

		.footer-content{
			padding: 0px;

			.footer-content-menu{
				width: 16.6667%;
				
				&:not(:first-child) {
					padding: 0px 20px;
				}
				&:first-child {
					padding-right: 20px;
				}
				margin-bottom: 30px;
			}
		}
	}
	.bottom-bar{
		margin: $footer-margin;
		padding: $footer-padding;
		font-size: 12px;
		line-height: 18px;

		.bottom-bar-content{
			border-top: 1px solid rgba(255,255,255,0.12);
			padding: 10px 0 10px 0;
			min-height: 60px;
			.copyright{
				color: #eee;
			}
		}

		.bottom-bar-list{
			list-style-type: none;
			li{
				float: right;

				&:not(:first-child) {
					border-right: 1px solid #fff;
					padding-right: 20px;
					margin-right: 16px;
				}

				a{
					color: #eee;
				}
			}
		}
	}
}

.line {
    border-top: 1px solid white;
    width: 90% !important
}

.fa-mobile {
    font-size: 20px
}

#contact {
    font-size: 15px
}

.grey-text {
    color: lightgrey
}

@media (max-width: $breakpoint-992) {
    .footer{
		.footer-row-content{
			.footer-content{
				flex-direction: column;
				.footer-content-menu{
					width: 100%;
					padding: 0 !important;
				}
			}
		}

		.bottom-bar{
			.bottom-bar-content{
				text-align: center;
			}
		}

		.bottom-bar-list-container{
			justify-content: center;
			width: 100%;
			display: flex;
			margin-top: 10px;
		}
	}
}


@media (max-width: $breakpoint-tablet) {
	.footer {
		.bottom-bar-list-container{
			display: none;
		}
	}
}

@media (max-width: $breakpoint-medium-phone) {
    .footer{
		.footer-row-content{
			display: none;
		}
		.bottom-bar-list-container{
			display: none;
		}
	}
}
