@import "global_var";

.professional-profile-page{
    .header{
        margin-top: 50px;
        margin-bottom: 50px;
        color: black;
        text-align: center;
        .title{
            font-weight: 600;
            font-size: 36px;
            line-height: 54px;
            color: #231D1D;
            margin-bottom: 1rem;
        }
        .bread-crumb{
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #231D1D;
            margin-bottom: 1rem;
        }
    }
    .header-line{
        border: 1px solid #231D1D;
    }

    .mobile-v{
        display: none !important;
    }

    .body{
        display: flex;
        padding-top: 2rem;
        padding-bottom: 2rem;
        .clinic-details{
            max-width: 350px;
            width: 100%;
            .img-slider{
                width: 100%;
                margin-bottom: 1rem;
                .carousel{
                    .carousel-inner{
                        border-radius: 25px;
                        .carousel-item{
                            img{
                                height: 300px;
                                background-color: black;
                                object-fit: contain;
                            }
                        }
                    }
                    .carousel-indicators{
                        li{
                            width: 10px;
                            height: 10px;
                            border-radius: 100%;
                        }
                    }
                }
            }
            .consultation-type{
                background: #F9F9F9;
                border-radius: 5px;
                padding: 10px 15px;
                color: #676767;
                margin-bottom: 1rem;
                .title{
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 18px;
                    margin-bottom: 10px;
                }
                .type{
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 18px;
                    align-items: center;
                    display: flex;
                    .consultation-icon{
                        width: 18px;
                        margin-right: 10px;
                    }
                }
            }
            .contact{
                margin-bottom: 1rem;
                .title{
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 27px;
                    color: #231D1D;
                    margin-bottom: 10px;
                }
                .details-container{
                    display: flex;
                    margin-bottom: 10px;
                    .icon-col{
                        padding-right: 10px;
                        img{
                            width: 25px;
                        }
                    }
                    .address-col{
                        .address-name{
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 21px;
                            a{
                                color: #676767;
                                text-decoration: none;
                                &:hover{
                                    color :#231D1D;
                                }
                            }
                        }
                        .address{
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 21px;
                            color: #676767;
                            margin-bottom: 15px;
                        }
                        .map-container{
                            width: 100%;
                            height: 300px;
                            margin-bottom: 15px;
                        }
                        .direction-tag{
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 21px;
                            color: #F25C54;
                            text-decoration: none;
                        }
                        .phone-email-container{
                            margin-top: 15px;
                            .contact-icon{
                                max-height: 50px;
                                max-width: 50px;
                                margin-right: 15px;
                            }
                        }
                    }
                }
            }
            .interest{
                background: #F9F9F9;
                border-radius: 5px;
                padding: 35px;
                width: 100%;
                .title{
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #231D1D;
                    text-align: center;
                }
                .interest-links{
                    display: flex;
                    flex-wrap: wrap;
                    .link-container{
                        cursor: pointer;
                        width: 50%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin: 15px 0;
                        text-decoration: none;
                        .interest-icon{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            height: 85px;
                            width: 85px;
                            background: #FFFFFF;
                            box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
                            border-radius: 32px;
                            img{
                                width: 55px;
                            }
                        }
                        .interest-title{
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 18px;           
                            color: #676767;
                            padding-top: 15px;
                        }
                    }
                }
            }
        }

        .doctor-details{
            margin-left: 60px;
            width: 100%;
            .details-container{
                flex: 0 70%;
                display:flex;
                margin-bottom: 3rem;
                .profile-pic{
                    padding-right: 60px;
                    img{
                        border-radius: 50%;
                        height: 200px;
                        width: 200px;
                        object-fit: cover;
                    }
                }
                .details{
                    width: 100%;
                    padding-right: 1rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .name-row{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .name{
                            font-style: normal;
                            font-weight: 600;
                            font-size: 22px;
                            line-height: 33px;
                            color: #231D1D;
                            margin: 2px 0;
                        }
                    }
                    .specialty{
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        color: #231D1D;
                        margin: 2px 0;
                    }
                    .feature-tags{
                        display: flex;
                        .feature-tag{
                            border-radius: 5px;
                            color: white;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 21px;
                            padding: 2px 5px;
                            display: inline-flex;
                            margin: 3px 0;
                            margin-right: 5px;
                            &.online-booking{
                                background: #346FF1;
                            }
                            &.corporate-panel{
                                background: #FD9B5B;
                            }
                        }
                    }

                    .bottom-details{
                        display: flex;
                        justify-content: flex-end;
                        .fav-btn{
                            &:focus{
                                box-shadow: none;
                            }
                            img{
                                width: 23px;
                            }
                        }
                    }
                }
            }
            .schedule-walk-in{
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 27px;
                color: #231D1D;
                margin-bottom: 25px;
            }
            .slots-container{
                .date-bar{
                    padding: 10px 15px;
                    display: flex;
                    justify-content: space-between;
                    background: #F9F9F9;
                    align-items: center;
                    margin-bottom: 1rem;
                    .btn-link{
                        color: black;
                        &.disabled{
                            background-color: transparent !important;
                            color: #6c757d;
                        }
                        &:focus{
                            outline: 0 !important;
                            box-shadow: none !important;
                        }
                    }
                    .date{
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        color: #676767;
                    }
                }
                .slots{
                    display: flex;
                    flex-wrap: wrap;
                    padding: 0 1rem;
                    .slot{
                        width: 100px;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 27px;
                        text-align: center;
                        border-radius: 5px;
                        padding: 10px;
                        margin: 5px 5px;
                        &.valid-slot{
                            background: #51B848;
                            color: #FFFFFF;
                            white-space: nowrap;
                            &:hover{
                                background: #28a745;
                            }
                        }
                        &.invalid-slot{
                        }
                    }
                    .slots-loader{
                        width: 100%;
                        text-align: center;
                        margin: 1rem 0;
                        .spinner{
                            color: #676767;
                        }
                    }
                    // &:after {
                    //     content: '';
                    //     flex: auto;
                    // }
                }
            }
            .details-line{
                border-color:#4E4E4E;
            }

            .teleconsult-container{
                margin: 40px 0;
                .div-header{
                    display: flex;
                    justify-content: space-between;
                    .header-title{
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 27px;
                        color: #231D1D;
                    }
                    .download-app{
                        font-size: 12px;
                        line-height: 18px;  
                        text-align: right;
                        text-decoration-line: underline;
                        color: #F25C54;
                    }
                }
                .teleconsult-lists{
                    display: flex;
                    margin-top: 30px;
                    .teleconsult-btn{
                        display: flex;
                        border: 2px solid #F25C54;
                        border-radius: 30px;
                        align-items: center;
                        padding: 5px 18px;
                        text-decoration: none;

                        &.chat-btn{
                            background-color: white;
                            color: #F25C54;
                            margin-right: 5px;
                        }
                        &.video-btn{
                            background-color: #F25C54;
                            color: white;
                            margin-left: 5px;
                        }

                        .teleconsult-icon{
                            margin-right: 10px;

                            .chat-icon{
                                width: 24px;
                            }
                        }
                        .button-info{
                            text-align: left;
                            .info-text{
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 18px;
                            }
                            .info-price{
                                font-style: normal;
                                font-weight: 600;
                                font-size: 15px;
                                line-height: 22px;
                            }
                        }
                    }
                }
            }

            .details-tabs-container{
                word-wrap: break-word;
                .nav-tabs{
                    border: 0px;
                    margin-bottom: 30px;
                    .nav-link{
                        width: 33.33%;
                        text-align: center;
                        border: 0px;
                        font-weight: normal;
                        font-size: 18px;
                        line-height: 27px;
                        color: #676767;
                        &.active{
                            border-bottom: 3px solid #F25C54;
                            font-weight: 500;
                            color: #F25C54;
                        }
                    }
                }
                .detail-container{
                    margin-bottom: 25px;
                    .tab-title{
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 27px;
                        color: #676767;
                        margin-bottom: 15px;
                    }
                    .sub-tab-title{
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 21px;
                        color: #231D1D;   
                        margin-bottom: 5px;                 
                    }
                    .tab-content{
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 21px;
                        color: #676767;
                        white-space: pre-line;
                        &.credential{
                            margin-bottom: 5px;
                            display: flex;
                            .cre-title{
                                width: 200px;
                            }
                            .punctuation{
                                padding-right: 15px;
                            }
                        }
                    }
                    .hour-note{
                        font-weight: 600;
                        font-style: italic;
                        font-size: 16px;
                        line-height: 21px;
                        color: #676767;
                        margin-top: 1rem;
                    }
                    .list{
                        padding-inline-start: 20px;
                        li{
                            margin-bottom: 5px;
                        }
                    }
                }

            }
        }
    }
}

@media (max-width: $breakpoint-992) {
    .professional-profile-page{
        .desktop-v{
            display: none;
        }
        .mobile-v{
            display: flex !important;
            flex-direction: column;
        }
        .body{
            .clinic-details{
                max-width: 100%;
                .img-slider{
                    position: relative;
                    .carousel{
                        .carousel-inner{
                            .carousel-item{
                                img{
                                    height: 400px;
                                }
                            }
                        }
                    }
                    .doctor-details{
                        margin-left: 0;
                        bottom: 0;
                        position: absolute;
                        .details-container{
                            flex-direction: column;
                            margin-left: 30px;
                            .profile-pic{
                                img{
                                    border: 2px solid white;
                                    height: 67px;
                                    width: 67px;
                                }
                            }
                            .details{
                                .name-row{
                                    .name{
                                        color: white;
                                    }
                                }
                                .specialty{
                                    color: white;
                                }
                            }
                        }
                    }
                    .fav-btn{
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        z-index: 1;
                        img{
                            width: 23px;
                        }
                        &:focus{
                            box-shadow: none;
                        }
                    }
                }
                .interest{
                    margin-top: 30px;
                    background: transparent;
                    padding: 0;
                    .title{
                        margin-bottom: 30px;
                    }
                    .interest-links{
                        justify-content: space-evenly;
                        .link-container{
                            width:auto;
                        }
                    }
                }
            }
            .doctor-details{
                margin-left: 0;
                .mobile-details-line{
                    width: 100%;
                }
                .slots-container{
                    margin-bottom: 30px;
                    .schedule-div{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .schedule-title{
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                            color: #231D1D;
                        }
                        .schedule-show{
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 18px;
                            text-align: right;
                            color: #F25C54;
                        }
                    }
                    .mobile-date-bar{
                        display: flex;
                        align-items: center;
                        padding: 20px 0;
                        img{
                            width: 20px;
                            margin-right: 10px;
                        }
                        .date{
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 18px;
                            color: #676767;
                        }
                    }

                    .slots{
                        flex-wrap: nowrap;
                        padding: 0;
                        overflow: scroll;
                    }
                }
                .teleconsult-container{
                    .teleconsult-lists{
                        justify-content: center;
                    }
                }
            }
        }
    }
}

@media (max-width: $breakpoint-medium-phone) {
    .professional-profile-page {
        .body{
            .doctor-details{
                .details-tabs-container{
                    .detail-container{
                        .tab-content{
                            &.credential{
                                .cre-title{
                                    &.day{
                                        width: 110px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}