@import "global_var";

$red: #ec2727;
$dark-red: #d32323;
$nav-width-992: 330px;

.header-logo {
    width: 100%;
}

.overlay-navbar{
    display: none;
    transition: opacity 5s ease-in;
}

.mobile-v{
    display: none !important;
}

.navbar{
    height: 100px;
    max-width: calc(1300px - 50px - 50px);
    position: relative;
    margin: 0 auto;
    padding: 0px !important;

    &.navbar-expand-lg{
        justify-content: space-between !important;
    }
    
    .navbar-collapse{
        flex-grow: 0 !important;
        
        .navbar-nav{
            align-items: center;
        }
    }

    .nav-item{
        &:first-child {
            margin-right: 12px;
        }
        &:not(:first-child) {
            margin-left: 12px;
            margin-right: 12px;
        }
        font-weight: 600;
        font-size: 13px;
        line-height: 22px;
        padding: 4px 4px 4px 4px;
        
        .nav-link{
            color: #4e4e4e !important;
            padding-right: 0.1rem !important;
            padding-left: 0.1rem !important;
            position: relative;

            &.active{
                color: $red !important;
                &:before{
                    visibility: visible;
                    width: 100%;
                }
            }

            &:before {
                content: "";
                position: absolute;
                width: 0;
                height: 2px;
                bottom: 5px;
                left: 0;
                background-color: $red;
                visibility: hidden;
                transition: all 0.2s ease-in-out;
            }
            &:hover {
                color: $red !important;
                &:before{
                    visibility: visible;
                    width: 100%;
                }
            }
        }

        &.more-btn{
            .dropdown-toggle{
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #FFFFFF;
            }
            .btn{
                border-radius: 30px;
                background-color: $red !important;
                border-color: $red !important;
                padding: 10px 20px;
            }
        }

        .login-btn{
            font-size: 13px;
            border-radius: 35px;
            background-color: $red !important;
            border-color: $red !important;
            font-weight: bold;
            margin: 18px 12px 18px 12px;
            padding: 20px;
            display: inline-block;

            .sign-in-text{
                &:hover{
                    color: #ffde00;
                }
            }

            &:hover{
                background-color: $dark-red !important;
                border-color: $dark-red !important;
            }
            &:focus{
                outline: 0 !important;
                box-shadow: none !important;
            }
        }

        &.dropdown{
            #collasible-nav-dropdown::after{
                display: none; 
            }
            .dropdown-menu{
                width: 250px;
                border-radius: 0;
                background-color: #f9f9f9 !important;
                padding: 9px 10px 9px 10px;
                box-shadow: 0 0 4px rgba(0,0,0, 0.2);
                border: none;

                .dropdown-item{
                    color: #4e4e4e;
                    margin: 0px 0px 2px 0px;
                    padding: 10px 10px 10px 10px;
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 22px;

                    &:hover{
                        background-color: #dbdbdb;
                    }
                }
            }
        }
    }
}

.top-banner{
    background-color: $red;
    color: white;
    font-size: 16px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;

    .top-banner-content{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 84%;
        align-items: center;

        .top-banner-socials{
            display: flex;
            flex-direction: row;
            
            .social-btn{
                border-radius: 100px !important;
                height: 26px;
                width: 26px;
                padding: 0px 0px !important;
                line-height: 26px;
                color: white !important;
                margin: 0 5px !important;
                background-color: $dark-red !important;
                display: flex !important;
                justify-content: center;
                align-items: center;

                &:hover{
                    background-color: #4e4e4e !important;
                }

                .social-icon{
                    font-size: 13px;
                    line-height: 26px;
                }
                
                .fb-icon{
                    font-size: 13px !important;
                    -webkit-text-stroke: 0.4px white; /* font color */
                }
                
                .twitter-icon{
                    font-size: 14px !important;
                    -webkit-text-stroke: 0.5px white; /* font color */
                }
                
                .insta-icon{
                    font-size: 16px !important;
                    -webkit-text-stroke: 0.4px white; /* font color */
                }
                
                .youtube-icon{
                    font-size: 14px !important;
                }
                
                .linked-icon{
                    font-size: 14px !important
                }
            }
        }
    }
}

.top-banner-contacts{
    display: flex;
    flex-direction: row;
    font: 13px "Poppins", Helvetica, Arial, Verdana, sans-serif;
    font-weight: bold;

    .top-banner-phone{
        margin-right: 10px;
        .phone-icon{
            margin-right: 5px;
            font-size: 17px !important; 
        }
    }
    .top-banner-email{
        margin-left: 10px;
    
        .envelope-icon{
            margin-right: 5px;
            font-size: 16px !important;
        }
    }
}


@media (max-width: $breakpoint-laptop) {
    .navbar{
        .nav-item{
            margin-left: 3px !important;
            margin-right: 3px !important;
        }
            .navbar-toggler{
                border: none !important;
                color: #333333 !important;
                font-size: 29px;

                .fa-bars{
                    -webkit-text-stroke: 1.5px #f7f6f6;
                }
            }
    }
}

@media (max-width: $breakpoint-992) {
    .desktop-v{
        display: none !important;
    }
    .mobile-v{
        display: flex !important;
    }

    .dropdown-toggle{
        &:after{
            display: none !important;
        }
    }
    .top-banner{
        .top-banner-content{
            justify-content: flex-start;
        }
    }

    .icon-rotates {
        font-size: 20px !important;
        -webkit-text-stroke: 2px white;
        color: rgba(51,51,51,0.17) !important;
        -moz-transition: all .3s ease-out;
        -webkit-transition: all .3s ease-out;
        transition: all .3s ease-out;
    }
    
    .icon-rotates.rotate {
        -moz-transition: rotate(45deg);
        -webkit-transition: rotate(45deg);
        transition: rotate(45deg);
    }
      
    .dropdown.show .icon-rotates {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .dropdown .dropdown-menu {
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
    
        max-height: 0;
        display: block;
        overflow: hidden;
        opacity: 0;
        padding: 0 !important;
    }

    .dropdown.show .dropdown-menu {
        max-height: 200px;
        opacity: 1;
    }

    .top-banner-contacts{
        .top-banner-phone{
            margin-left: 10px;
        }
    }
    .navbar{
        padding: 0 50px 0 50px !important;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px rgba(215, 215, 215, 0.75);

        .navbar-brand{
            .header-logo {
                width: 80%;
            }
        }

        .nav-item{
            font-size: 16px;
            font-weight: 500;
            .nav-link{
                color: #333 !important;
                &:before{
                    background-color: transparent;
                }
            }
            .nav-title{
                justify-content: space-between;
                display: flex;
            }
            .login-btn{
                margin: 1rem 0;
                width: 100%;
            }
            &.dropdown{
                .dropdown-menu{
                    background-color: transparent !important;
                    box-shadow: none;
                    font-size: 13px;
                    color: #333;
                    margin-left: 20px !important;
                    .dropdown-item{
                        padding-top: 3px;
                        padding-bottom: 3px;

                        &.active, &:active{
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }

    // testing 
    .mobile-nav-close{
        width: 100%;;
        text-align: right;
        margin-right: -15px;
        .navbar-toggler{
            -webkit-text-stroke: 3.5px #ec2727;
            background-color: #ec2727;
            color: white !important;
            border-radius: 0;
            font-size: 30px;
            padding: 1px 6px;
            margin-top: 1rem;
        }
    }
    .navbar-collapse {
        position: fixed;
        top: 0;
        right: 0;
        height: 100%;
        padding-left: 30px;
        padding-right: 30px;
        background-color: white;
        width: $nav-width-992;
        z-index: 2;
        overflow: scroll;
    }

    .navbar-collapse.collapsing {
        height: 100%;
        right: -75%;
        transition: height 0s ease;
    }

    // .navbar-toggler.collapsed ~ .navbar-collapse {
    //     transition: right 400ms ease-in;
    // }

    .navbar-collapse.show {
        height: 100%;
        right: 0;
        transition: right 400ms ease-in-out;
        
        .nav-item{
            width: 100%;

            &:not(:last-child) {
                border-bottom: 1px solid rgba(51,51,51,0.12);
            }

            .login-btn{
                padding: 15px 20px;
            }
        }

        .overlay-navbar{
            top: -100px;
            height: 100%;
            left: 0;
            right: $nav-width-992;
            position: fixed;
            background-color: rgba(17,17,17,0.5);
            z-index: 1;
            // display: block;
            transition: right 400ms ease-in-out;
        }
    }
    //testing end

    .mobile-nav-contacts{
        padding: 30px 0;
        width: 100%;
        color: #b1b4b8;
        font-weight: 500;
        font-size: 13px;
        .top-banner-phone, .top-banner-email{
            margin: 5px 0;
            i{
                margin-right: 5px;
            }
        }
    }
}

@media (max-width: $breakpoint-tablet) {
    .top-banner{
        display: none;
    }
}

@media (max-width: $breakpoint-large-phone) {
    .navbar{
        padding: 0 20px !important;
        height: 80px;
    }
}
