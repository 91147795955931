.map{
    width: 100%;
    height: 100%;
}

.gm-style .gm-style-iw-c{
    background: #FFFFFF !important;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2) !important;
    border-radius: 0px !important;
}

.gm-ui-hover-effect{
    display: none !important;
}

.marker-container{
    width: 150px;
    .marker-info-container{
        display: flex;
        flex-direction: column;
        overflow: auto !important;
    
        .marker-info-name{
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            text-align: center;
            color: #231D1D;
            background: #F9F9F9;
            border-radius: 5px;
            padding: 10px;
        }
        .marker-info-specialty{
            text-align:center;
            font-size: 13px;
            line-height: 20px;
            color: #4E4E4E;
            padding: 7px;
        }
    }

    &.ResultMapView{
        width: 300px;
        .marker-info-container{
            padding: 15px;
            .marker-info-name{
                padding: 1rem;
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 15px;
            }
            .marker-info-specialty{
                font-size: 16px;
                line-height: 24px;
                padding: 0;
            }
        }
    }

    .button-group{
        display: flex;

        .btn{
            flex: 1 50%;
            padding-top: 15px;
            padding-bottom: 15px;
            border-radius: 0px;
            border-top: 1px solid #D7D7D7;
            font-size: 18px;
            line-height: 27px;
            text-align: center;
            letter-spacing: 0.428571px;
            color: #F25C54;
            &:focus{
                outline: 0 !important;
                box-shadow: none !important;
            }
        }
        .btn-call{
            border-right: 0.5px solid #D7D7D7;
        }
    }
}