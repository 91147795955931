.favourite-page{
    margin-bottom: 50px;
    .header{
        margin-top: 50px;
        margin-bottom: 50px;
        color: black;
        text-align: center;
        .title{
            font-weight: 600;
            font-size: 36px;
            line-height: 54px;
            color: #231D1D;
            margin-bottom: 1rem;
        }
        .bread-crumb{
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #231D1D;
            margin-bottom: 1rem;
        }
    }
    .header-line{
        border: 1px solid #231D1D;
        margin-bottom: 50px;
    }

    .MuiPagination-root{
        .MuiPagination-ul{
            margin: 1.5rem auto 3rem auto;
        }
    }
    
    .body{

        .favourites-list{
            display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;

            &.add-after-space{
                .prof-card:last-child{
                    margin-right: 0.8rem;
                }
                &:after {
                    content: "";
                    flex: 1 0 48%;
                }
            }
        }
        .empty-div{
            padding: 5rem;
            text-align: center;
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            color: #231D1D;
        }
        
    }
}